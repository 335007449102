import questionProps from "../questionProps";
import {BuildType, MortgageTypes, PropertyTypes} from "../../../../../../../models";
export function addressKnown(props) {
    return {
        label: "Property Found",
        text: "Have you found a property?",
        name: "addressKnown",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            return form.getFieldValue('mortgageType') !== 'PURCHASE'
        },
        model: "property",
        rules: [
            {required: true, message: `An answer is required`}
        ],
        target: `properties[${props.index}].addressKnown`
    }
}
export function address(props) {
    return {
        label: "Address",
        text: "What is the property's address?",
        name: "address",
        answer: {
            type: "address"
        },
        model: "property",
        rules: [
            {required: true, message: `An address is required`}
        ],
        target: `properties[${props.index}].address`
    }
}
export function siteOwned(props){
    return {
        label: "Site Owned",
        text: "Do you own the site on which the property is to be built?",
        name: "siteOwned",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form)=>form.getFieldValue('buildType') !== BuildType.SELFBUILD,
        model: "property",
        target: `properties[${props.index}].siteOwned`
    }
}
export function siteValue(props){
    return {
        label: "Site Value",
        text: "What is the value of the site?",
        name: "siteValue",
        answer: {
            type: "euro",
        },
        hides: (form)=>form.getFieldValue('buildType') !== BuildType.SELFBUILD,
        model: "property",
        target: `properties[${props.index}].siteValue`
    }
}
export function constructionCosts(props){
    return {
        label: "Construction Costs",
        text: "What are the construction costs?",
        name: "constructionCosts",
        answer: {
            type: "euro",
        },
        hides: (form)=>form.getFieldValue('buildType') !== BuildType.SELFBUILD,
        model: "property",
        target: `properties[${props.index}].constructionCosts`
    }
}
export function propertyPrice(props){
    return {
        label: "Sale Price",
        text: "What is price of the property?",
        name: "propertyPrice",
        answer: {
            type: "euro",
        },
        model: "property",
        target: `properties[${props.index}].propertyPrice`
    }
}
function propertyType(props) {
    return {
        label: "Property Type",
        text: "What type of property is it?",
        name: "propertyType",
        answer: {
            type: "select",
            choices: [
                {value: PropertyTypes.FLAT, text: "Flat"},
                {value: PropertyTypes.APARTMENT, text: "Apartment"},
                {value: PropertyTypes.DETACHED, text: "Detached House"},
                {value: PropertyTypes.SEMIDETACHED, text: "Semi-detached House"},
                {value: PropertyTypes.TERRACE, text: "Terraced House"}
            ]
        },
        model: "property",
        rules: [
            {required: true, message: `An answer is required`}
        ],
        target: `properties[${props.index}].propertyType`
    }
}
function buildType(props) {

    return {
        label: "Property Type",
        text: "Is the property newly built or second hand",
        name: "buildType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Newly Built",
                    value: BuildType.NEW
                },
                {
                    text: "Second Hand",
                    value:BuildType.SECONDHAND
                }
            ]
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `properties[${props.index}].buildType`
    }
}
function berScore(props) {
    return {
        label: "BER Score",
        text: "What is the property's BER score?",
        name: 'berScore',
        answer: {
            type: 'select',
            choices: [
                {
                    value: 'A1',
                    text: 'A1',

                },
                {
                    value: 'A2',
                    text: 'A2',

                },
                {
                    value: 'A3',
                    text: 'A3',

                },
                {
                    value: 'B1',
                    text: 'B1',

                },
                {
                    value: 'B2',
                    text: 'B2',

                },
                {
                    value: 'B3',
                    text: 'B3',

                },
                {
                    value: 'C1',
                    text: 'C1',

                },
                {
                    value: 'C2',
                    text: 'C2',
                },
                {
                    value: 'C3',
                    text: 'C3',

                },
                {
                    value: 'D1',
                    text: 'D1',

                },
                {
                    value: 'D2',
                    text: 'D2',
                },
                {
                    value: 'E1',
                    text: 'E1',
                },
                {
                    value: 'E2',
                    text: 'E2',
                },
                {
                    value: 'F',
                    text: 'F',
                },
                {
                    value: 'G',
                    text: 'G',
                },
                {
                    value: 'UNKNOWN',
                    text: "I don't know",
                }
            ]
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `properties[${props.index}].berScore`
    }
}

// function bedrooms(props) {
//     return {
//         label: "Bedrooms",
//         text: "How many bedrooms does the property have?",
//         name: "bedrooms",
//         answer: {
//             type: "int"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 1,
//                 message: 'The value must be grater than 1',
//             }
//         ],
//         target: `properties[${props.index}].bedrooms`
//     }
// }
// function bathrooms(props) {
//     return {
//         label: "Bathrooms",
//         text: "How many bathrooms does the property have?",
//         name: "bathrooms",
//         answer: {
//             type: "int"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 1,
//                 message: 'The value must be grater than 1',
//             }
//         ],
//         target: `properties[${props.index}].bathrooms`
//     }
// }
// function propertyValue(props) {
//     return {
//         label: "Property Value",
//         text: "What is the estimated value of the property?",
//         name: "propertyValue",
//         answer: {
//             type: "euro"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 100000,
//                 message: 'The value must be grater than €100,000',
//             }
//         ],
//         target: `properties[${props.index}].propertyValue`
//     }
// }
// function origPurchasePrice(props) {
//     return {
//         label: "Purchase Price",
//         text: "What was the original purchase price of the property?",
//         name: "origPurchasePrice",
//         answer: {
//             type: "euro"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 100000,
//                 message: 'The value must be grater than €100,000',
//             }
//         ],
//         target: `properties[${props.index}].origPurchasePrice`
//     }
// }
// function yearOfPurchase(props) {
//     return {
//         label: "Year of Purchase",
//         text: "In what year was the property acquired?",
//         name: "yearOfPurchase",
//         answer: {
//             type: "int"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A year is required`},
//             {
//                 type: 'number',
//                 min: 1900,
//                 max: (new Date()).getFullYear() - 1,
//                 message: 'The value must a valid year',
//             }
//         ],
//         target: `properties[${props.index}].yearOfPurchase`
//     }
// }
// function residingHere(props) {
//     return {
//         label: "Residing Here",
//         text: "Is this the property in which you currently live?",
//         name: "residingHere",
//         answer: {
//             type: "choice",
//             choices: [
//                 {value: true, text: "Yes"},
//                 {value: false, text: "No"}
//             ]
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `An answer is required`}
//         ],
//         target: `properties[${props.index}].residingHere`
//     }
// }
// function adultsBoarding(props) {
//     const otherApplicant = props.mortgage.twoApplicants ? " and the other applicant" : ""
//     const areThere = props.mortgage.mortgageType === MortgageTypes.PURCHASE ? "will there be" : "are there"
//     return {
//         label: "Adults Boarding",
//         text: `Apart from you${otherApplicant}, ${areThere} any other adults living in the property?`,
//         name: "adultsBoarding",
//         answer: {
//             type: "choice",
//             choices: [
//                 {value: true, text: "Yes"},
//                 {value: false, text: "No"}
//             ]
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `An answer is required`}
//         ],
//         target: `properties[${props.index}].adultsBoarding`
//     }
// }
// function adultsBoardingCount(props) {
//     return {
//         label: "Adults Boarding",
//         text: "How many other adults live there?",
//         name: "adultsBoardingCount",
//         answer: {
//             type: "int"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 1,
//                 message: 'The value must be grater than 1',
//             }
//         ],
//         target: `properties[${props.index}].adultsBoardingCount`
//     }
// }
// function isRented(props) {
//     return {
//         label: "Rented",
//         text: "Is the property rented?",
//         name: "isRented",
//         answer: {
//             type: "choice",
//             choices: [
//                 {value: true, text: "Yes"},
//                 {value: false, text: "No"}
//             ]
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `An answer is required`}
//         ],
//         target: `properties[${props.index}].isRented`
//     }
// }
// function monthlyRent(props) {
//     return {
//         label: "Monthly Rent",
//         text: "What is the monthly rent?",
//         name: "monthlyRent",
//         answer: {
//             type: "euro"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 1,
//                 message: 'The value must be grater than 1',
//             }
//         ],
//         target: `properties[${props.index}].monthlyRent`
//     }
// }
// function isMortgaged(props) {
//
//     return {
//         label: "Mortgaged",
//         text: "Is the property mortgaged?",
//         name: "isMortgaged",
//         answer: {
//             type: "choice",
//             choices: [
//                 {value: true, text: "Yes"},
//                 {value: false, text: "No"}
//             ]
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `An answer is required`}
//         ],
//         target: `properties[${props.index}].isMortgaged`
//     }
// }
// function remainingTerm(props) {
//     return {
//         label: "Remaining Term",
//         text: "What is the remaining term of the mortgage?",
//         name: "remainingTerm",
//         answer: {
//             type: "int"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 1,
//                 message: 'The value must be grater than 1',
//             }
//         ],
//         target: `properties[${props.index}].remainingTerm`
//     }
// }
// function lender(props) {
//     return {
//         label: "Lender",
//         text: "Who is the lender?",
//         name: "lender",
//         answer: {
//             type: "lender"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A name is required`}
//         ],
//         target: `properties[${props.index}].lender`
//     }
// }
// function accountNumber(props) {
//     return {
//         label: "Account Number",
//         text: "What is the mortgage account number?",
//         name: "accountNumber",
//         answer: {
//             type: "text"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `An account number is required`}
//         ],
//         target: `properties[${props.index}].accountNumber`
//     }
// }
// function monthlyPayment(props) {
//     return {
//         label: "Monthly Payment",
//         text: "What is the monthly payment?",
//         name: "monthlyPayment",
//         answer: {
//             type: "euro"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 1,
//                 message: 'The value must be grater than 1',
//             }
//         ],
//         target: `properties[${props.index}].monthlyPayment`
//     }
//
// }
// function currentRate(props) {
//     return {
//         label: "Current Rate",
//         text: "What is the current rate?",
//         name: "currentRate",
//         answer: {
//             type: "float"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 0,
//                 message: 'The value must be grater than 0',
//             }
//         ],
//         target: `properties[${props.index}].currentRate`
//     }
// }
// function outstanding(props) {
//     return {
//         label: "Outstanding",
//         text: "What is the outstanding balance?",
//         name: "outstanding",
//         answer: {
//             type: "euro"
//         },
//         model: "properties",
//         rules: [
//             {required: true, message: `A number is required`},
//             {
//                 type: 'number',
//                 min: 1,
//                 message: 'The value must be grater than 1',
//             }
//         ],
//         target: `properties[${props.index}].outstanding`
//     }
//
// }

function property (props) {
    if (!props.index){
        props.index = props.mortgage.properties[0].id
    }
    const betterProps = questionProps(props)
    let addressK = addressKnown(betterProps)
    delete addressK.hides
    let questions = [
        addressK,
        propertyType(betterProps),
        propertyPrice(betterProps),
        buildType(betterProps),
        address(betterProps),
        berScore(betterProps),
    ]

    return questions
}
export default property