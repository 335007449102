// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const BoolLike = {
  "NOTSURE": "NOTSURE",
  "TRUE": "TRUE",
  "FALSE": "FALSE"
};

const EmploymentBases = {
  "FULLTIME": "FULLTIME",
  "TEMPFULLTIME": "TEMPFULLTIME",
  "PARTTIME": "PARTTIME",
  "TEMPPARTTIME": "TEMPPARTTIME",
  "CONTRACT": "CONTRACT"
};

const PurchaseStages = {
  "RESEARCHING": "RESEARCHING",
  "VIEWING": "VIEWING",
  "OFFERMADE": "OFFERMADE",
  "OFFERACCEPTED": "OFFERACCEPTED"
};

const SectionTypes = {
  "QUESTIONS": "QUESTIONS",
  "UPLOAD": "UPLOAD"
};

const MortgageTypes = {
  "REFINANCE": "REFINANCE",
  "PURCHASE": "PURCHASE"
};

const ApplicationTypes = {
  "LANDLORD": "LANDLORD",
  "OWNHOME": "OWNHOME",
  "MOVINGHOME": "MOVINGHOME",
  "FIRSTTIME": "FIRSTTIME",
  "SECONDTIME": "SECONDTIME"
};

const PropertyTypes = {
  "DETACHED": "DETACHED",
  "SEMIDETACHED": "SEMIDETACHED",
  "TERRACE": "TERRACE",
  "APARTMENT": "APARTMENT",
  "FLAT": "FLAT"
};

const DebtTypes = {
  "CREDITCARD": "CREDITCARD",
  "STORECARD": "STORECARD",
  "LOAN": "LOAN",
  "OVERDRAFT": "OVERDRAFT",
  "BUSINESSLOAN": "BUSINESSLOAN"
};

const AssetTypes = {
  "SAVING": "SAVING",
  "INVESTMENT": "INVESTMENT"
};

const Frequencies = {
  "WEEKLY": "WEEKLY",
  "FORTNIGHTLY": "FORTNIGHTLY",
  "MONTHLY": "MONTHLY",
  "QUARTERLY": "QUARTERLY",
  "YEARLY": "YEARLY",
  "STATIC": "STATIC",
  "OCCASIONALLY": "OCCASIONALLY"
};

const EmploymentTypes = {
  "EMPLOYED": "EMPLOYED",
  "SELFEMPLOYED": "SELFEMPLOYED",
  "HOMEMAKER": "HOMEMAKER",
  "STUDENT": "STUDENT"
};

const PersonalTitles = {
  "MR": "MR",
  "MRS": "MRS",
  "MISS": "MISS",
  "DR": "DR"
};

const PersonalMaritalStatuses = {
  "SINGLE": "SINGLE",
  "COHABITING": "COHABITING",
  "MARRIED": "MARRIED",
  "REMARRIED": "REMARRIED",
  "SEPARATED": "SEPARATED",
  "DIVORCED": "DIVORCED",
  "WIDOWED": "WIDOWED"
};

const PersonalAccommodationSituations = {
  "OWNED": "OWNED",
  "RENTED": "RENTED",
  "RELATIVES": "RELATIVES",
  "OTHER": "OTHER",
  "TOGETHER": "TOGETHER"
};

const PropertyIntendedUses = {
  "FIRSTTIME": "FIRSTTIME",
  "MOVING": "MOVING",
  "BUYTOLET": "BUYTOLET",
  "HOLIDAYHOME": "HOLIDAYHOME"
};

const UploadStatuses = {
  "PENDING": "PENDING",
  "ACCEPTABLE": "ACCEPTABLE",
  "REJECTED": "REJECTED",
  "UPLOADING": "UPLOADING",
  "REVIEWING": "REVIEWING",
  "EXPIRED": "EXPIRED"
};

const ApplicationStages = {
  "SETUP": "SETUP",
  "APPLY": "APPLY",
  "TASKS": "TASKS",
  "CALCULATE": "CALCULATE",
  "SIGN": "SIGN",
  "PREAPPROVAL": "PREAPPROVAL",
  "APPROVAL": "APPROVAL"
};

const ApplicationStatuses = {
  "DELAYED": "DELAYED",
  "ACTIVE": "ACTIVE",
  "SUSPENDED": "SUSPENDED",
  "COMPLETED": "COMPLETED",
  "OFFLINE": "OFFLINE"
};

const IncomeSources = {
  "SALARY": "SALARY",
  "DRAWINGS": "DRAWINGS"
};

const BuildType = {
  "NEW": "NEW",
  "SECONDHAND": "SECONDHAND",
  "SELFBUILD": "SELFBUILD",
  "UNKNOWN": "UNKNOWN"
};

const OverlayItemTypes = {
  "TEXT": "TEXT",
  "CHECK": "CHECK"
};

const TemplateTypes = {
  "OVERLAID": "OVERLAID",
  "COPIED": "COPIED",
  "GENERATED": "GENERATED",
  "PERMALINK": "PERMALINK",
  "FILLED": "FILLED"
};

const ContactMethods = {
  "EMAIL": "EMAIL",
  "PHONE": "PHONE",
  "DONT": "DONT"
};

const InvitationTypes = {
  "APPLICANT1": "APPLICANT1",
  "APPLICANT2": "APPLICANT2"
};

const ReplyRequestTypes = {
  "PREAPPROVAL": "PREAPPROVAL",
  "APPROVAL": "APPROVAL"
};

const AppointmentTypes = {
  "CALL": "CALL",
  "VIDEO": "VIDEO"
};

const MortgageQualificationStatus = {
  "QUALIFIED": "QUALIFIED",
  "UNQUALIFIED": "UNQUALIFIED",
  "MAYBE": "MAYBE"
};

const MortgageActiveAgents = {
  "BROKER": "BROKER",
  "USER": "USER",
  "LENDER": "LENDER"
};

const { PublicCouncils, PublicLenderRate, AdminPdfOverlayTemplate, AdminPdfOverlayTemplateItem, AdminLenderFiles, AdminBackendUsers, AdminLender, AdminEmails, AdminEmailReplyRequest, AdminEmailAttachments, AdminUserCalculations, AdminSystemToDo, AdminTextractedStatement, AdminMortgageAccountSettings, AdminMortgageTasks, AdminMortgageTaskComponent, AdminMortgageTaskToDo, AdminMortgageNotes, AdminMortgageStageTask, AdminMortgageStageNotifications, AdminMortgageStageNotified, AdminMortgageDocumentContent, AdminMortgageSettings, AdminLastViewedMortgage, AdminMortgageLenderTest, AdminMortgageLenderTestPlaygroundTest, AdminMortgageUploadStatementBasicInfo, AdminMortgageSectionVerification, AdminMortgageUploadVerifiedPoint, AdminMortgageSectionVerificationNote, AdminMortgageSubmission, AdminMortgageSubmissionIssue, AdminMortgageProtectionInsurance, UserPdfId, UserMortgageAppointment, UserMortgageRecommendation, UserMortgageQuery, UserMortgage, UserMortgageInvitation, UserMortgageDocuments, UserMortgageProgress, UserMortgageProgressNotes, UserMortgageDynamicSection, UserMortgageUploads, UserMortgageRequirement, UserMortgageProperty, UserMortgageExisting, UserMortgageNeeds, UserMortgageSolicitor, UserMortgagePropertyOnMortgage, UserApplicant, UserApplicantLastHere, UserApplicantCurrentAccount, UserApplicantBusinessCurrentAccount, UserApplicantTax, UserApplicantPersonal, UserApplicantIncome, UserApplicantFinancial, UserApplicantEmployment, UserApplicantEmploymentIncome, UserApplicantAsset, UserApplicantDebt, UserApplicantBusinessDebt, UserApplicantProperty, UserApplicantAlternateEmailAddresses, UserApplicantActivity, UserApplicantHealth } = initSchema(schema);

export {
  PublicCouncils,
  PublicLenderRate,
  AdminPdfOverlayTemplate,
  AdminPdfOverlayTemplateItem,
  AdminLenderFiles,
  AdminBackendUsers,
  AdminLender,
  AdminEmails,
  AdminEmailReplyRequest,
  AdminEmailAttachments,
  AdminUserCalculations,
  AdminSystemToDo,
  AdminTextractedStatement,
  AdminMortgageAccountSettings,
  AdminMortgageTasks,
  AdminMortgageTaskComponent,
  AdminMortgageTaskToDo,
  AdminMortgageNotes,
  AdminMortgageStageTask,
  AdminMortgageStageNotifications,
  AdminMortgageStageNotified,
  AdminMortgageDocumentContent,
  AdminMortgageSettings,
  AdminLastViewedMortgage,
  AdminMortgageLenderTest,
  AdminMortgageLenderTestPlaygroundTest,
  AdminMortgageUploadStatementBasicInfo,
  AdminMortgageSectionVerification,
  AdminMortgageUploadVerifiedPoint,
  AdminMortgageSectionVerificationNote,
  AdminMortgageSubmission,
  AdminMortgageSubmissionIssue,
  AdminMortgageProtectionInsurance,
  UserPdfId,
  UserMortgageAppointment,
  UserMortgageRecommendation,
  UserMortgageQuery,
  UserMortgage,
  UserMortgageInvitation,
  UserMortgageDocuments,
  UserMortgageProgress,
  UserMortgageProgressNotes,
  UserMortgageDynamicSection,
  UserMortgageUploads,
  UserMortgageRequirement,
  UserMortgageProperty,
  UserMortgageExisting,
  UserMortgageNeeds,
  UserMortgageSolicitor,
  UserMortgagePropertyOnMortgage,
  UserApplicant,
  UserApplicantLastHere,
  UserApplicantCurrentAccount,
  UserApplicantBusinessCurrentAccount,
  UserApplicantTax,
  UserApplicantPersonal,
  UserApplicantIncome,
  UserApplicantFinancial,
  UserApplicantEmployment,
  UserApplicantEmploymentIncome,
  UserApplicantAsset,
  UserApplicantDebt,
  UserApplicantBusinessDebt,
  UserApplicantProperty,
  UserApplicantAlternateEmailAddresses,
  UserApplicantActivity,
  UserApplicantHealth,
  BoolLike,
  EmploymentBases,
  PurchaseStages,
  SectionTypes,
  MortgageTypes,
  ApplicationTypes,
  PropertyTypes,
  DebtTypes,
  AssetTypes,
  Frequencies,
  EmploymentTypes,
  PersonalTitles,
  PersonalMaritalStatuses,
  PersonalAccommodationSituations,
  PropertyIntendedUses,
  UploadStatuses,
  ApplicationStages,
  ApplicationStatuses,
  IncomeSources,
  BuildType,
  OverlayItemTypes,
  TemplateTypes,
  ContactMethods,
  InvitationTypes,
  ReplyRequestTypes,
  AppointmentTypes,
  MortgageQualificationStatus,
  MortgageActiveAgents
};