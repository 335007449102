import React from 'react';
import questionProps from "../questionProps";
import {twoApplicants, applicationTypePurchase, applicationTypeRefinance} from "./setupProfile";
import {ApplicationTypes, BoolLike, BuildType, MortgageTypes} from "../../../../../../../models";
import {Popover} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

export function buildType(props) {
    return {
        label : "Property Type",
        text  : "What type of property are you thinking about?",
        name  : "buildType",
        answer: {
            type   : "select",
            choices: [
                {
                    text : "Newly Built",
                    value: BuildType.NEW
                },
                {
                    text : "Second Hand",
                    value: BuildType.SECONDHAND
                },
                {
                    text : "Self-Build",
                    value: BuildType.SELFBUILD
                },
                {
                    text : "Don't know yet",
                    value: BuildType.UNKNOWN
                }
            ]
        },
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.PURCHASE || form.getFieldValue('addressKnown') !== true
        },
        model : "properties",
        rules : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        target: `properties[${props.index}].buildType`
    }
}
export function loanRequired(props) {
    return {
        label       : 'Loan Required',
        name        : 'loanRequired',
        text        : `Enter the loan amount required`,
        model       : `requirement`,
        answer      : {
            type: 'euro',
            step: 1000
        },
        dependencies: [
            'propertyValue',
            'mortgageType',
            'applicationType'
        ],
        // hides       : (form) => {
        //     return form.getFieldValue('mortgageType') === MortgageTypes.REFINANCE
        // },
        rules       : [
            ({getFieldValue}) => ({
                validator(_, value) {
                    const estimatedValue = getFieldValue('propertyValue')
                    const mortgageType = getFieldValue('mortgageType')
                    const applicationType = getFieldValue('applicationType')
                    if (!estimatedValue || !value) {
                        return Promise.resolve()
                    }
                    // Check if the value is less than the minimum amount
                    if (value < 100000) {
                        return Promise.reject(new Error('We can only help with loans above €100,000'));
                    }
                    if (mortgageType === MortgageTypes.PURCHASE) {
                        let maxLTV = .8
                        if (applicationType === ApplicationTypes.FIRSTTIME) {
                            maxLTV = .9
                        }
                        let maxVal = estimatedValue * maxLTV

                        if (!value || value <= maxVal) {
                            return Promise.resolve();
                        }
                        if (maxLTV === .9) {

                            return Promise.reject(new Error('Loan cannot be more than 90% of the property value for first time buyers'));
                        }
                        return Promise.reject(new Error('Loan cannot be more than 80% of the property value'));
                    }
                    return Promise.resolve();
                }
            }),
        ],
        target      : `requirement.loanRequired`,
    }
}
export function propertyType(props) {
    return {
        label : "Property Type",
        text  : "What type of property are you thinking about?",
        name  : "propertyType",
        answer: {
            type   : "select",
            choices: [
                {
                    text : "Detached",
                    value: "DETACHED"
                },
                {
                    text : "Semi-detached",
                    value: "SEMIDETACHED"
                },
                {
                    text : "Terrace",
                    value: "TERRACE"
                },
                {
                    text : "Apartment",
                    value: "APARTMENT"
                },
                {
                    text : "Flat",
                    value: "FLAT"
                }
            ]
        },
        model : "properties",
        rules : [],
        target: `properties[${props.index}].propertyType`
    }
}
export function loanTerm(props) {
    return {
        label : "Mortgage Term",
        text  : "Over how many years would you prefer to repay the mortgage?",
        name  : "loanTerm",
        answer: {
            type        : "int",
            defaultValue: 35
        },
        model : "requirement",
        rules : [
            {
                required: true,
                message : `A number is required`
            },
            {
                type   : 'number',
                min    : 5,
                max    : 35,
                message: 'The value must be between 5 and 35',
            }
        ],
        target: `requirement.loanTerm`
    }
}
export function propertyValuePurchase(props) {
    return {
        label : "Property Value",
        text  : "What is the overall estimated value of the new property?",
        name  : "propertyValue",
        answer: {
            type: "euro",
        },
        model : "requirement",
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.PURCHASE
        },
        rules : [
            {
                required: true,
                message : `An amount is required`
            },
            {
                type   : 'number',
                min    : 150000,
                max    : 10000000,
                message: 'The value must be between €150,000 and €10,000,000'
            }
        ],
        target: `properties[${props.index}].propertyValue`
    }
}
export function mortgageType(props) {
    return {
        label  : "Type of Mortgage",
        text   : "Select your requirement",
        subtext: "Select the type of mortgage you need",
        name   : "mortgageType",
        answer : {
            type   : "select",
            choices: [
                {
                    text : "New mortgage",
                    value: MortgageTypes.PURCHASE,
                },
                {
                    text : "Refinance an existing mortgage",
                    value: MortgageTypes.REFINANCE,
                }
            ]
        },
        model  : "mortgage",
        rules  : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        target : `mortgageType`,
        watch  : (form, value) => {
            form.setFieldValue('applicationType', null)
        }
    }
}
export function currentMortgageProvider(props) {
    return {
        name  : 'currentMortgageProvider',
        label : 'Current Mortgage Provider',
        text  : 'Who is your current mortgage provider?',
        answer: {
            type: 'mortgageProvider'
        },
        model : 'requirement',
        rules : [
            {
                required: true,
                message : `A mortgage provider is required`
            }
        ],
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.REFINANCE
        },
        target: `properties[${props.index}].lender`
    }
}
export function currentOutstandingMortgage(props) {
    return {
        name  : 'currentOutstandingMortgage',
        label : 'Current Outstanding Mortgage',
        text  : 'What is the outstanding balance on your current mortgage?',
        answer: {
            type: 'euro'
        },
        model : 'properties',
        rules : [
            {
                required: true,
                message : `An amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                max    : 10000000,
                message: 'The value must be between €1 and €10,000,000'
            }
        ],
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.REFINANCE
        },
        target: `properties[${props.index}].outstanding`
    }
}
export function propertyValueRefinance(props) {
    return {
        label : "Property Value",
        text  : "What is the estimated value of your property?",
        name  : "propertyValue",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.REFINANCE
        },
        model : "requirement",
        rules : [
            {
                required: true,
                message : `An amount is required`
            },
            {
                type   : 'number',
                min    : 150000,
                max    : 10000000,
                message: 'The value must be between €150,000 and €10,000,000'
            }
        ],
        target: `properties[${props.index}].propertyValue`
    }
}
export function helpToBuyScheme(props) {
    let Pop = () => {
        return (<Popover content={<div className="max-w-sm">
            <p>The Help To Buy Scheme is only available for purchases of <span
                className="text-cyan-500">newly-built</span> apartments and houses with purchase prices of <span
                className="text-cyan-500"> €500,000 or less </span></p>
        </div>} trigger="click">
            <InfoCircleOutlined className="text-cyan-500"/>
        </Popover>)
    }

    return {
        label : "Help to Buy Scheme",
        text  : <>Are you availing of the Help to Buy Scheme - a tax refund to help with your deposit? (<strong>will
            limit your options to a new build less than €500k</strong>) <Pop/></>,
        name  : "helpToBuyScheme",
        answer: {
            type        : "select",
            defaultValue: BoolLike.TRUE,
            choices     : [
                {
                    text : "Yes",
                    value: BoolLike.TRUE
                },
                {
                    text : "No",
                    value: BoolLike.FALSE
                },
                {
                    text : "Don't know",
                    value: BoolLike.NOTSURE
                }
            ]
        },
        hides : (form) => {
            if (form.getFieldValue('mortgageType') !== MortgageTypes.PURCHASE) {
                return true
            }
            if (form.getFieldValue('applicationType') !== ApplicationTypes.FIRSTTIME) {
                return true
            }
            if (form.getFieldValue('buildType') === BuildType.SECONDHAND) {
                return true
            }
            if (form.getFieldValue('propertyValue') > 500000) {
                return true
            }
            return false
        },
        model : "requirement",
        rules : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        target: `requirement.helpToBuyScheme`
    }
}
export function firstHomeScheme(props) {
    return {
        label : "First Time Buyer Scheme",
        text  : "Are you availing of the First Home scheme - a shared equity scheme facilitated by the county council?",
        name  : "firstHomeScheme",
        answer: {
            type   : "select",
            choices: [
                {
                    text : "Yes",
                    value: BoolLike.TRUE
                },
                {
                    text : "No",
                    value: BoolLike.FALSE
                },
                {
                    text : "Don't know",
                    value: BoolLike.NOTSURE
                }
            ]
        },
        hides : (form) => {
            if (form.getFieldValue('mortgageType') !== MortgageTypes.PURCHASE) {
                return true
            }
            if (form.getFieldValue('applicationType') !== ApplicationTypes.FIRSTTIME) {
                return true
            }
            if (form.getFieldValue('buildType') === BuildType.SECONDHAND) {
                return true
            }
            if (form.getFieldValue('propertyValue') > 500000) {
                return true
            }
            return false
        },
        model : "requirement",
        rules : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        target: `requirement.firstHomeScheme`
    }

}
export function receivingGifts(props) {
    return {
        label : "Gifts Expected",
        text  : "Will you receive gifts from relatives or friends to make up your deposit?",
        name  : "receivingGifts",
        answer: {
            type   : "choice",
            choices: [
                {
                    text : "Yes",
                    value: true,
                },
                {
                    text : "No",
                    value: false,
                }
            ]
        },
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== 'PURCHASE'
        },
        model : "requirement",
        rules : [
            {
                required: true,
                message : `A selection is required`
            }
        ],
        target: `requirement.receivingGifts`
    }
}
export function giftAmount(props) {
    return {
        label : 'Gifts Amount',
        name  : 'depositGifts',
        text  : 'What is that value of all gifts you expect to receive?',
        model : 'requirement',
        answer: {
            type: 'euro',
            step: 100
        },
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== 'PURCHASE' || form.getFieldValue('receivingGifts') !== true
        },
        rules : [
            {
                type   : 'number',
                min    : 1,
                message: "The minimum value here is €1"
            },
            {
                type   : 'number',
                max    : 1000000,
                message: "The maximum value here is €1,000,000"
            }
        ],
        target: `requirement.depositGifts`
    }
}
export function depositSavings(props) {
    return {
        label : 'Own Savings',
        name  : 'depositSavings',
        text  : 'What amount of your own savings is going towards the deposit?',
        model : 'requirement',
        answer: {
            type: 'euro',
            step: 100
        },
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.PURCHASE
        },
        rules : [
            {
                type   : 'number',
                min    : 1,
                message: "The minimum value here is €1"
            },
            {
                type   : 'number',
                max    : 1000000,
                message: "The maximum value here is €1,000,000"
            }
        ],
        target: `requirement.depositSavings`
    }
}
export function berScore(props) {
    return {
        label : "BER Score",
        text  : "What is the property's BER score?",
        name  : 'berScore',
        answer: {
            type   : 'select',
            choices: [
                {
                    value: 'A1',
                    text : 'A1',

                },
                {
                    value: 'A2',
                    text : 'A2',

                },
                {
                    value: 'A3',
                    text : 'A3',

                },
                {
                    value: 'B1',
                    text : 'B1',

                },
                {
                    value: 'B2',
                    text : 'B2',

                },
                {
                    value: 'B3',
                    text : 'B3',

                },
                {
                    value: 'C1',
                    text : 'C1',

                },
                {
                    value: 'C2',
                    text : 'C2',
                },
                {
                    value: 'C3',
                    text : 'C3',

                },
                {
                    value: 'D1',
                    text : 'D1',

                },
                {
                    value: 'D2',
                    text : 'D2',
                },
                {
                    value: 'E1',
                    text : 'E1',
                },
                {
                    value: 'E2',
                    text : 'E2',
                },
                {
                    value: 'F',
                    text : 'F',
                },
                {
                    value: 'G',
                    text : 'G',
                },
                {
                    value: 'UNKNOWN',
                    text : "I don't know",
                }
            ]
        },
        rules : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        hides : (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.REFINANCE
        },
        target: `properties[${props.index}].berScore`
    }
}
function SetupGoal(props) {
    const betterProps = questionProps(props)
    const propertyProps = {
        ...betterProps,
        index: betterProps.mortgage?.properties[0]?.id
    }
    const questions = [
        mortgageType(betterProps),
        applicationTypePurchase(betterProps),
        applicationTypeRefinance(betterProps),
        buildType(propertyProps),
        berScore(propertyProps),
        propertyValuePurchase(propertyProps),
        propertyValueRefinance(propertyProps),

        loanRequired(betterProps),
        loanTerm(betterProps),
        twoApplicants(betterProps),

        receivingGifts(betterProps),
        giftAmount(betterProps),
        helpToBuyScheme(betterProps),
        firstHomeScheme(betterProps),

    ]
    return questions
}

export default SetupGoal;