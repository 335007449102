/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePublicCouncils = /* GraphQL */ `
  subscription OnCreatePublicCouncils(
    $filter: ModelSubscriptionPublicCouncilsFilterInput
  ) {
    onCreatePublicCouncils(filter: $filter) {
      id
      fullName
      shortName
      maxPrice
      maxApartment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePublicCouncils = /* GraphQL */ `
  subscription OnUpdatePublicCouncils(
    $filter: ModelSubscriptionPublicCouncilsFilterInput
  ) {
    onUpdatePublicCouncils(filter: $filter) {
      id
      fullName
      shortName
      maxPrice
      maxApartment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePublicCouncils = /* GraphQL */ `
  subscription OnDeletePublicCouncils(
    $filter: ModelSubscriptionPublicCouncilsFilterInput
  ) {
    onDeletePublicCouncils(filter: $filter) {
      id
      fullName
      shortName
      maxPrice
      maxApartment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePublicLenderRate = /* GraphQL */ `
  subscription OnCreatePublicLenderRate(
    $filter: ModelSubscriptionPublicLenderRateFilterInput
  ) {
    onCreatePublicLenderRate(filter: $filter) {
      id
      lenderID
      rate
      maxLTV
      term
      discount
      minimum
      lenderCode
      promotions
      conditions
      buyToLet
      product
      interestOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePublicLenderRate = /* GraphQL */ `
  subscription OnUpdatePublicLenderRate(
    $filter: ModelSubscriptionPublicLenderRateFilterInput
  ) {
    onUpdatePublicLenderRate(filter: $filter) {
      id
      lenderID
      rate
      maxLTV
      term
      discount
      minimum
      lenderCode
      promotions
      conditions
      buyToLet
      product
      interestOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePublicLenderRate = /* GraphQL */ `
  subscription OnDeletePublicLenderRate(
    $filter: ModelSubscriptionPublicLenderRateFilterInput
  ) {
    onDeletePublicLenderRate(filter: $filter) {
      id
      lenderID
      rate
      maxLTV
      term
      discount
      minimum
      lenderCode
      promotions
      conditions
      buyToLet
      product
      interestOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminPdfOverlayTemplate = /* GraphQL */ `
  subscription OnCreateAdminPdfOverlayTemplate(
    $filter: ModelSubscriptionAdminPdfOverlayTemplateFilterInput
  ) {
    onCreateAdminPdfOverlayTemplate(filter: $filter) {
      id
      lender
      lenderCode
      stage
      fileName
      templateType
      mortgageType
      companyFolder
      documentNotes
      archive
      bucketKey
      clientSigns
      docusign
      defaultFolder
      defaultPrivate
      items {
        items {
          id
          name
          pageNo
          x
          y
          type
          style
          templateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminPdfOverlayTemplate = /* GraphQL */ `
  subscription OnUpdateAdminPdfOverlayTemplate(
    $filter: ModelSubscriptionAdminPdfOverlayTemplateFilterInput
  ) {
    onUpdateAdminPdfOverlayTemplate(filter: $filter) {
      id
      lender
      lenderCode
      stage
      fileName
      templateType
      mortgageType
      companyFolder
      documentNotes
      archive
      bucketKey
      clientSigns
      docusign
      defaultFolder
      defaultPrivate
      items {
        items {
          id
          name
          pageNo
          x
          y
          type
          style
          templateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminPdfOverlayTemplate = /* GraphQL */ `
  subscription OnDeleteAdminPdfOverlayTemplate(
    $filter: ModelSubscriptionAdminPdfOverlayTemplateFilterInput
  ) {
    onDeleteAdminPdfOverlayTemplate(filter: $filter) {
      id
      lender
      lenderCode
      stage
      fileName
      templateType
      mortgageType
      companyFolder
      documentNotes
      archive
      bucketKey
      clientSigns
      docusign
      defaultFolder
      defaultPrivate
      items {
        items {
          id
          name
          pageNo
          x
          y
          type
          style
          templateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminPdfOverlayTemplateItem = /* GraphQL */ `
  subscription OnCreateAdminPdfOverlayTemplateItem(
    $filter: ModelSubscriptionAdminPdfOverlayTemplateItemFilterInput
  ) {
    onCreateAdminPdfOverlayTemplateItem(filter: $filter) {
      id
      name
      pageNo
      x
      y
      type
      style
      templateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminPdfOverlayTemplateItem = /* GraphQL */ `
  subscription OnUpdateAdminPdfOverlayTemplateItem(
    $filter: ModelSubscriptionAdminPdfOverlayTemplateItemFilterInput
  ) {
    onUpdateAdminPdfOverlayTemplateItem(filter: $filter) {
      id
      name
      pageNo
      x
      y
      type
      style
      templateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminPdfOverlayTemplateItem = /* GraphQL */ `
  subscription OnDeleteAdminPdfOverlayTemplateItem(
    $filter: ModelSubscriptionAdminPdfOverlayTemplateItemFilterInput
  ) {
    onDeleteAdminPdfOverlayTemplateItem(filter: $filter) {
      id
      name
      pageNo
      x
      y
      type
      style
      templateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminLenderFiles = /* GraphQL */ `
  subscription OnCreateAdminLenderFiles(
    $filter: ModelSubscriptionAdminLenderFilesFilterInput
  ) {
    onCreateAdminLenderFiles(filter: $filter) {
      id
      lender
      folder
      description
      fileName
      bucketKey
      contentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminLenderFiles = /* GraphQL */ `
  subscription OnUpdateAdminLenderFiles(
    $filter: ModelSubscriptionAdminLenderFilesFilterInput
  ) {
    onUpdateAdminLenderFiles(filter: $filter) {
      id
      lender
      folder
      description
      fileName
      bucketKey
      contentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminLenderFiles = /* GraphQL */ `
  subscription OnDeleteAdminLenderFiles(
    $filter: ModelSubscriptionAdminLenderFilesFilterInput
  ) {
    onDeleteAdminLenderFiles(filter: $filter) {
      id
      lender
      folder
      description
      fileName
      bucketKey
      contentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminBackendUsers = /* GraphQL */ `
  subscription OnCreateAdminBackendUsers(
    $filter: ModelSubscriptionAdminBackendUsersFilterInput
  ) {
    onCreateAdminBackendUsers(filter: $filter) {
      id
      firstName
      surname
      sub
      email
      phone
      mailAccount
      color
      lastActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminBackendUsers = /* GraphQL */ `
  subscription OnUpdateAdminBackendUsers(
    $filter: ModelSubscriptionAdminBackendUsersFilterInput
  ) {
    onUpdateAdminBackendUsers(filter: $filter) {
      id
      firstName
      surname
      sub
      email
      phone
      mailAccount
      color
      lastActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminBackendUsers = /* GraphQL */ `
  subscription OnDeleteAdminBackendUsers(
    $filter: ModelSubscriptionAdminBackendUsersFilterInput
  ) {
    onDeleteAdminBackendUsers(filter: $filter) {
      id
      firstName
      surname
      sub
      email
      phone
      mailAccount
      color
      lastActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminLender = /* GraphQL */ `
  subscription OnCreateAdminLender(
    $filter: ModelSubscriptionAdminLenderFilterInput
  ) {
    onCreateAdminLender(filter: $filter) {
      id
      lender
      address
      approverFirstName
      approverSurname
      approverEmail
      approverPhone
      lenderCode
      rates {
        items {
          id
          lenderID
          rate
          maxLTV
          term
          discount
          minimum
          lenderCode
          promotions
          conditions
          buyToLet
          product
          interestOnly
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      minimumLoan
      minimumTerm
      maximumLoan
      maximumTerm
      onlineAccount
      overlayTemplates {
        items {
          id
          lender
          lenderCode
          stage
          fileName
          templateType
          mortgageType
          companyFolder
          documentNotes
          archive
          bucketKey
          clientSigns
          docusign
          defaultFolder
          defaultPrivate
          items {
            items {
              id
              name
              pageNo
              x
              y
              type
              style
              templateId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      files {
        items {
          id
          lender
          folder
          description
          fileName
          bucketKey
          contentType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultConditions
      defaultPromotions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminLender = /* GraphQL */ `
  subscription OnUpdateAdminLender(
    $filter: ModelSubscriptionAdminLenderFilterInput
  ) {
    onUpdateAdminLender(filter: $filter) {
      id
      lender
      address
      approverFirstName
      approverSurname
      approverEmail
      approverPhone
      lenderCode
      rates {
        items {
          id
          lenderID
          rate
          maxLTV
          term
          discount
          minimum
          lenderCode
          promotions
          conditions
          buyToLet
          product
          interestOnly
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      minimumLoan
      minimumTerm
      maximumLoan
      maximumTerm
      onlineAccount
      overlayTemplates {
        items {
          id
          lender
          lenderCode
          stage
          fileName
          templateType
          mortgageType
          companyFolder
          documentNotes
          archive
          bucketKey
          clientSigns
          docusign
          defaultFolder
          defaultPrivate
          items {
            items {
              id
              name
              pageNo
              x
              y
              type
              style
              templateId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      files {
        items {
          id
          lender
          folder
          description
          fileName
          bucketKey
          contentType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultConditions
      defaultPromotions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminLender = /* GraphQL */ `
  subscription OnDeleteAdminLender(
    $filter: ModelSubscriptionAdminLenderFilterInput
  ) {
    onDeleteAdminLender(filter: $filter) {
      id
      lender
      address
      approverFirstName
      approverSurname
      approverEmail
      approverPhone
      lenderCode
      rates {
        items {
          id
          lenderID
          rate
          maxLTV
          term
          discount
          minimum
          lenderCode
          promotions
          conditions
          buyToLet
          product
          interestOnly
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      minimumLoan
      minimumTerm
      maximumLoan
      maximumTerm
      onlineAccount
      overlayTemplates {
        items {
          id
          lender
          lenderCode
          stage
          fileName
          templateType
          mortgageType
          companyFolder
          documentNotes
          archive
          bucketKey
          clientSigns
          docusign
          defaultFolder
          defaultPrivate
          items {
            items {
              id
              name
              pageNo
              x
              y
              type
              style
              templateId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      files {
        items {
          id
          lender
          folder
          description
          fileName
          bucketKey
          contentType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultConditions
      defaultPromotions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminEmails = /* GraphQL */ `
  subscription OnCreateAdminEmails(
    $filter: ModelSubscriptionAdminEmailsFilterInput
  ) {
    onCreateAdminEmails(filter: $filter) {
      id
      from
      to
      cc
      bcc
      subject
      hasAttachments
      v2Attachments
      html
      attachments
      sortDate
      datetimeReceived
      datetimeSent
      messageId
      originalId
      isReceived
      isReceivedInt
      readOn
      readBy
      replyRequestId
      appsyncVersion
      actionedOn
      actionedBy
      AdminEmailAttachments {
        items {
          id
          contentType
          fileSize
          content
          fileName
          adminemailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userapplicantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminEmails = /* GraphQL */ `
  subscription OnUpdateAdminEmails(
    $filter: ModelSubscriptionAdminEmailsFilterInput
  ) {
    onUpdateAdminEmails(filter: $filter) {
      id
      from
      to
      cc
      bcc
      subject
      hasAttachments
      v2Attachments
      html
      attachments
      sortDate
      datetimeReceived
      datetimeSent
      messageId
      originalId
      isReceived
      isReceivedInt
      readOn
      readBy
      replyRequestId
      appsyncVersion
      actionedOn
      actionedBy
      AdminEmailAttachments {
        items {
          id
          contentType
          fileSize
          content
          fileName
          adminemailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userapplicantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminEmails = /* GraphQL */ `
  subscription OnDeleteAdminEmails(
    $filter: ModelSubscriptionAdminEmailsFilterInput
  ) {
    onDeleteAdminEmails(filter: $filter) {
      id
      from
      to
      cc
      bcc
      subject
      hasAttachments
      v2Attachments
      html
      attachments
      sortDate
      datetimeReceived
      datetimeSent
      messageId
      originalId
      isReceived
      isReceivedInt
      readOn
      readBy
      replyRequestId
      appsyncVersion
      actionedOn
      actionedBy
      AdminEmailAttachments {
        items {
          id
          contentType
          fileSize
          content
          fileName
          adminemailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userapplicantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminEmailReplyRequest = /* GraphQL */ `
  subscription OnCreateAdminEmailReplyRequest(
    $filter: ModelSubscriptionAdminEmailReplyRequestFilterInput
  ) {
    onCreateAdminEmailReplyRequest(filter: $filter) {
      id
      receivedOn
      mortgageId
      emailId
      replyTo
      requestType
      requestBody
      itemsLoaded
      noteBody
      assocEmails
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      repliedBy
      repliedOn
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminEmailReplyRequest = /* GraphQL */ `
  subscription OnUpdateAdminEmailReplyRequest(
    $filter: ModelSubscriptionAdminEmailReplyRequestFilterInput
  ) {
    onUpdateAdminEmailReplyRequest(filter: $filter) {
      id
      receivedOn
      mortgageId
      emailId
      replyTo
      requestType
      requestBody
      itemsLoaded
      noteBody
      assocEmails
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      repliedBy
      repliedOn
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminEmailReplyRequest = /* GraphQL */ `
  subscription OnDeleteAdminEmailReplyRequest(
    $filter: ModelSubscriptionAdminEmailReplyRequestFilterInput
  ) {
    onDeleteAdminEmailReplyRequest(filter: $filter) {
      id
      receivedOn
      mortgageId
      emailId
      replyTo
      requestType
      requestBody
      itemsLoaded
      noteBody
      assocEmails
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      repliedBy
      repliedOn
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminEmailAttachments = /* GraphQL */ `
  subscription OnCreateAdminEmailAttachments(
    $filter: ModelSubscriptionAdminEmailAttachmentsFilterInput
  ) {
    onCreateAdminEmailAttachments(filter: $filter) {
      id
      contentType
      fileSize
      content
      fileName
      adminemailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminEmailAttachments = /* GraphQL */ `
  subscription OnUpdateAdminEmailAttachments(
    $filter: ModelSubscriptionAdminEmailAttachmentsFilterInput
  ) {
    onUpdateAdminEmailAttachments(filter: $filter) {
      id
      contentType
      fileSize
      content
      fileName
      adminemailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminEmailAttachments = /* GraphQL */ `
  subscription OnDeleteAdminEmailAttachments(
    $filter: ModelSubscriptionAdminEmailAttachmentsFilterInput
  ) {
    onDeleteAdminEmailAttachments(filter: $filter) {
      id
      contentType
      fileSize
      content
      fileName
      adminemailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminUserCalculations = /* GraphQL */ `
  subscription OnCreateAdminUserCalculations(
    $filter: ModelSubscriptionAdminUserCalculationsFilterInput
  ) {
    onCreateAdminUserCalculations(filter: $filter) {
      id
      loan
      term
      monthly
      ftb
      income
      htbs
      rebate
      fhs
      cc
      type
      shared
      price
      source
      applied
      ber
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminUserCalculations = /* GraphQL */ `
  subscription OnUpdateAdminUserCalculations(
    $filter: ModelSubscriptionAdminUserCalculationsFilterInput
  ) {
    onUpdateAdminUserCalculations(filter: $filter) {
      id
      loan
      term
      monthly
      ftb
      income
      htbs
      rebate
      fhs
      cc
      type
      shared
      price
      source
      applied
      ber
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminUserCalculations = /* GraphQL */ `
  subscription OnDeleteAdminUserCalculations(
    $filter: ModelSubscriptionAdminUserCalculationsFilterInput
  ) {
    onDeleteAdminUserCalculations(filter: $filter) {
      id
      loan
      term
      monthly
      ftb
      income
      htbs
      rebate
      fhs
      cc
      type
      shared
      price
      source
      applied
      ber
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminSystemToDo = /* GraphQL */ `
  subscription OnCreateAdminSystemToDo(
    $filter: ModelSubscriptionAdminSystemToDoFilterInput
  ) {
    onCreateAdminSystemToDo(filter: $filter) {
      id
      text
      author
      target
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminSystemToDo = /* GraphQL */ `
  subscription OnUpdateAdminSystemToDo(
    $filter: ModelSubscriptionAdminSystemToDoFilterInput
  ) {
    onUpdateAdminSystemToDo(filter: $filter) {
      id
      text
      author
      target
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminSystemToDo = /* GraphQL */ `
  subscription OnDeleteAdminSystemToDo(
    $filter: ModelSubscriptionAdminSystemToDoFilterInput
  ) {
    onDeleteAdminSystemToDo(filter: $filter) {
      id
      text
      author
      target
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminTextractedStatement = /* GraphQL */ `
  subscription OnCreateAdminTextractedStatement(
    $filter: ModelSubscriptionAdminTextractedStatementFilterInput
  ) {
    onCreateAdminTextractedStatement(filter: $filter) {
      id
      bucket
      key
      forms
      tables
      formArray
      tableArray
      mortgageID
      inProgress
      jobID
      uploadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminTextractedStatement = /* GraphQL */ `
  subscription OnUpdateAdminTextractedStatement(
    $filter: ModelSubscriptionAdminTextractedStatementFilterInput
  ) {
    onUpdateAdminTextractedStatement(filter: $filter) {
      id
      bucket
      key
      forms
      tables
      formArray
      tableArray
      mortgageID
      inProgress
      jobID
      uploadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminTextractedStatement = /* GraphQL */ `
  subscription OnDeleteAdminTextractedStatement(
    $filter: ModelSubscriptionAdminTextractedStatementFilterInput
  ) {
    onDeleteAdminTextractedStatement(filter: $filter) {
      id
      bucket
      key
      forms
      tables
      formArray
      tableArray
      mortgageID
      inProgress
      jobID
      uploadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageAccountSettings = /* GraphQL */ `
  subscription OnCreateAdminMortgageAccountSettings(
    $filter: ModelSubscriptionAdminMortgageAccountSettingsFilterInput
  ) {
    onCreateAdminMortgageAccountSettings(filter: $filter) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageAccountSettings = /* GraphQL */ `
  subscription OnUpdateAdminMortgageAccountSettings(
    $filter: ModelSubscriptionAdminMortgageAccountSettingsFilterInput
  ) {
    onUpdateAdminMortgageAccountSettings(filter: $filter) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageAccountSettings = /* GraphQL */ `
  subscription OnDeleteAdminMortgageAccountSettings(
    $filter: ModelSubscriptionAdminMortgageAccountSettingsFilterInput
  ) {
    onDeleteAdminMortgageAccountSettings(filter: $filter) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageTasks = /* GraphQL */ `
  subscription OnCreateAdminMortgageTasks(
    $filter: ModelSubscriptionAdminMortgageTasksFilterInput
  ) {
    onCreateAdminMortgageTasks(filter: $filter) {
      id
      body
      author
      pinned
      due
      deleted
      target
      targetStarted
      targetLastActive
      completedOn
      completedBy
      paused
      closedOn
      closedBy
      folder
      userFolder
      userSortPosition
      tags
      currentOwner
      lastMessageBy
      lastMessageOn
      lastMessage
      toDos {
        items {
          id
          taskID
          completedOn
          completedBy
          toDoItem
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pauses
      components {
        items {
          id
          taskID
          component
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageTasks = /* GraphQL */ `
  subscription OnUpdateAdminMortgageTasks(
    $filter: ModelSubscriptionAdminMortgageTasksFilterInput
  ) {
    onUpdateAdminMortgageTasks(filter: $filter) {
      id
      body
      author
      pinned
      due
      deleted
      target
      targetStarted
      targetLastActive
      completedOn
      completedBy
      paused
      closedOn
      closedBy
      folder
      userFolder
      userSortPosition
      tags
      currentOwner
      lastMessageBy
      lastMessageOn
      lastMessage
      toDos {
        items {
          id
          taskID
          completedOn
          completedBy
          toDoItem
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pauses
      components {
        items {
          id
          taskID
          component
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageTasks = /* GraphQL */ `
  subscription OnDeleteAdminMortgageTasks(
    $filter: ModelSubscriptionAdminMortgageTasksFilterInput
  ) {
    onDeleteAdminMortgageTasks(filter: $filter) {
      id
      body
      author
      pinned
      due
      deleted
      target
      targetStarted
      targetLastActive
      completedOn
      completedBy
      paused
      closedOn
      closedBy
      folder
      userFolder
      userSortPosition
      tags
      currentOwner
      lastMessageBy
      lastMessageOn
      lastMessage
      toDos {
        items {
          id
          taskID
          completedOn
          completedBy
          toDoItem
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pauses
      components {
        items {
          id
          taskID
          component
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageTaskComponent = /* GraphQL */ `
  subscription OnCreateAdminMortgageTaskComponent(
    $filter: ModelSubscriptionAdminMortgageTaskComponentFilterInput
  ) {
    onCreateAdminMortgageTaskComponent(filter: $filter) {
      id
      taskID
      component
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageTaskComponent = /* GraphQL */ `
  subscription OnUpdateAdminMortgageTaskComponent(
    $filter: ModelSubscriptionAdminMortgageTaskComponentFilterInput
  ) {
    onUpdateAdminMortgageTaskComponent(filter: $filter) {
      id
      taskID
      component
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageTaskComponent = /* GraphQL */ `
  subscription OnDeleteAdminMortgageTaskComponent(
    $filter: ModelSubscriptionAdminMortgageTaskComponentFilterInput
  ) {
    onDeleteAdminMortgageTaskComponent(filter: $filter) {
      id
      taskID
      component
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageTaskToDo = /* GraphQL */ `
  subscription OnCreateAdminMortgageTaskToDo(
    $filter: ModelSubscriptionAdminMortgageTaskToDoFilterInput
  ) {
    onCreateAdminMortgageTaskToDo(filter: $filter) {
      id
      taskID
      completedOn
      completedBy
      toDoItem
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageTaskToDo = /* GraphQL */ `
  subscription OnUpdateAdminMortgageTaskToDo(
    $filter: ModelSubscriptionAdminMortgageTaskToDoFilterInput
  ) {
    onUpdateAdminMortgageTaskToDo(filter: $filter) {
      id
      taskID
      completedOn
      completedBy
      toDoItem
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageTaskToDo = /* GraphQL */ `
  subscription OnDeleteAdminMortgageTaskToDo(
    $filter: ModelSubscriptionAdminMortgageTaskToDoFilterInput
  ) {
    onDeleteAdminMortgageTaskToDo(filter: $filter) {
      id
      taskID
      completedOn
      completedBy
      toDoItem
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageNotes = /* GraphQL */ `
  subscription OnCreateAdminMortgageNotes(
    $filter: ModelSubscriptionAdminMortgageNotesFilterInput
  ) {
    onCreateAdminMortgageNotes(filter: $filter) {
      id
      body
      author
      pinned
      deleted
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageNotes = /* GraphQL */ `
  subscription OnUpdateAdminMortgageNotes(
    $filter: ModelSubscriptionAdminMortgageNotesFilterInput
  ) {
    onUpdateAdminMortgageNotes(filter: $filter) {
      id
      body
      author
      pinned
      deleted
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageNotes = /* GraphQL */ `
  subscription OnDeleteAdminMortgageNotes(
    $filter: ModelSubscriptionAdminMortgageNotesFilterInput
  ) {
    onDeleteAdminMortgageNotes(filter: $filter) {
      id
      body
      author
      pinned
      deleted
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageStageTask = /* GraphQL */ `
  subscription OnCreateAdminMortgageStageTask(
    $filter: ModelSubscriptionAdminMortgageStageTaskFilterInput
  ) {
    onCreateAdminMortgageStageTask(filter: $filter) {
      id
      mortgageID
      stage
      name
      deleted
      due
      completedOn
      completedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageStageTask = /* GraphQL */ `
  subscription OnUpdateAdminMortgageStageTask(
    $filter: ModelSubscriptionAdminMortgageStageTaskFilterInput
  ) {
    onUpdateAdminMortgageStageTask(filter: $filter) {
      id
      mortgageID
      stage
      name
      deleted
      due
      completedOn
      completedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageStageTask = /* GraphQL */ `
  subscription OnDeleteAdminMortgageStageTask(
    $filter: ModelSubscriptionAdminMortgageStageTaskFilterInput
  ) {
    onDeleteAdminMortgageStageTask(filter: $filter) {
      id
      mortgageID
      stage
      name
      deleted
      due
      completedOn
      completedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageStageNotifications = /* GraphQL */ `
  subscription OnCreateAdminMortgageStageNotifications(
    $filter: ModelSubscriptionAdminMortgageStageNotificationsFilterInput
  ) {
    onCreateAdminMortgageStageNotifications(filter: $filter) {
      id
      mortgageID
      stage
      name
      history {
        items {
          id
          sentTime
          sentBy
          data
          notificationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageStageNotifications = /* GraphQL */ `
  subscription OnUpdateAdminMortgageStageNotifications(
    $filter: ModelSubscriptionAdminMortgageStageNotificationsFilterInput
  ) {
    onUpdateAdminMortgageStageNotifications(filter: $filter) {
      id
      mortgageID
      stage
      name
      history {
        items {
          id
          sentTime
          sentBy
          data
          notificationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageStageNotifications = /* GraphQL */ `
  subscription OnDeleteAdminMortgageStageNotifications(
    $filter: ModelSubscriptionAdminMortgageStageNotificationsFilterInput
  ) {
    onDeleteAdminMortgageStageNotifications(filter: $filter) {
      id
      mortgageID
      stage
      name
      history {
        items {
          id
          sentTime
          sentBy
          data
          notificationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageStageNotified = /* GraphQL */ `
  subscription OnCreateAdminMortgageStageNotified(
    $filter: ModelSubscriptionAdminMortgageStageNotifiedFilterInput
  ) {
    onCreateAdminMortgageStageNotified(filter: $filter) {
      id
      sentTime
      sentBy
      data
      notificationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageStageNotified = /* GraphQL */ `
  subscription OnUpdateAdminMortgageStageNotified(
    $filter: ModelSubscriptionAdminMortgageStageNotifiedFilterInput
  ) {
    onUpdateAdminMortgageStageNotified(filter: $filter) {
      id
      sentTime
      sentBy
      data
      notificationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageStageNotified = /* GraphQL */ `
  subscription OnDeleteAdminMortgageStageNotified(
    $filter: ModelSubscriptionAdminMortgageStageNotifiedFilterInput
  ) {
    onDeleteAdminMortgageStageNotified(filter: $filter) {
      id
      sentTime
      sentBy
      data
      notificationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageDocumentContent = /* GraphQL */ `
  subscription OnCreateAdminMortgageDocumentContent(
    $filter: ModelSubscriptionAdminMortgageDocumentContentFilterInput
  ) {
    onCreateAdminMortgageDocumentContent(filter: $filter) {
      id
      mortgageID
      paragraph
      folder
      document
      sequence
      html
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageDocumentContent = /* GraphQL */ `
  subscription OnUpdateAdminMortgageDocumentContent(
    $filter: ModelSubscriptionAdminMortgageDocumentContentFilterInput
  ) {
    onUpdateAdminMortgageDocumentContent(filter: $filter) {
      id
      mortgageID
      paragraph
      folder
      document
      sequence
      html
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageDocumentContent = /* GraphQL */ `
  subscription OnDeleteAdminMortgageDocumentContent(
    $filter: ModelSubscriptionAdminMortgageDocumentContentFilterInput
  ) {
    onDeleteAdminMortgageDocumentContent(filter: $filter) {
      id
      mortgageID
      paragraph
      folder
      document
      sequence
      html
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageSettings = /* GraphQL */ `
  subscription OnCreateAdminMortgageSettings(
    $filter: ModelSubscriptionAdminMortgageSettingsFilterInput
  ) {
    onCreateAdminMortgageSettings(filter: $filter) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedApplyDone
      markedApplyDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageSettings = /* GraphQL */ `
  subscription OnUpdateAdminMortgageSettings(
    $filter: ModelSubscriptionAdminMortgageSettingsFilterInput
  ) {
    onUpdateAdminMortgageSettings(filter: $filter) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedApplyDone
      markedApplyDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageSettings = /* GraphQL */ `
  subscription OnDeleteAdminMortgageSettings(
    $filter: ModelSubscriptionAdminMortgageSettingsFilterInput
  ) {
    onDeleteAdminMortgageSettings(filter: $filter) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedApplyDone
      markedApplyDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminLastViewedMortgage = /* GraphQL */ `
  subscription OnCreateAdminLastViewedMortgage(
    $filter: ModelSubscriptionAdminLastViewedMortgageFilterInput
  ) {
    onCreateAdminLastViewedMortgage(filter: $filter) {
      id
      mortgageID
      lastViewedOn
      lastViewedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminLastViewedMortgage = /* GraphQL */ `
  subscription OnUpdateAdminLastViewedMortgage(
    $filter: ModelSubscriptionAdminLastViewedMortgageFilterInput
  ) {
    onUpdateAdminLastViewedMortgage(filter: $filter) {
      id
      mortgageID
      lastViewedOn
      lastViewedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminLastViewedMortgage = /* GraphQL */ `
  subscription OnDeleteAdminLastViewedMortgage(
    $filter: ModelSubscriptionAdminLastViewedMortgageFilterInput
  ) {
    onDeleteAdminLastViewedMortgage(filter: $filter) {
      id
      mortgageID
      lastViewedOn
      lastViewedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageLenderTest = /* GraphQL */ `
  subscription OnCreateAdminMortgageLenderTest(
    $filter: ModelSubscriptionAdminMortgageLenderTestFilterInput
  ) {
    onCreateAdminMortgageLenderTest(filter: $filter) {
      id
      lenderCode
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageLenderTest = /* GraphQL */ `
  subscription OnUpdateAdminMortgageLenderTest(
    $filter: ModelSubscriptionAdminMortgageLenderTestFilterInput
  ) {
    onUpdateAdminMortgageLenderTest(filter: $filter) {
      id
      lenderCode
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageLenderTest = /* GraphQL */ `
  subscription OnDeleteAdminMortgageLenderTest(
    $filter: ModelSubscriptionAdminMortgageLenderTestFilterInput
  ) {
    onDeleteAdminMortgageLenderTest(filter: $filter) {
      id
      lenderCode
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageLenderTestPlaygroundTest = /* GraphQL */ `
  subscription OnCreateAdminMortgageLenderTestPlaygroundTest(
    $filter: ModelSubscriptionAdminMortgageLenderTestPlaygroundTestFilterInput
  ) {
    onCreateAdminMortgageLenderTestPlaygroundTest(filter: $filter) {
      id
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageLenderTestPlaygroundTest = /* GraphQL */ `
  subscription OnUpdateAdminMortgageLenderTestPlaygroundTest(
    $filter: ModelSubscriptionAdminMortgageLenderTestPlaygroundTestFilterInput
  ) {
    onUpdateAdminMortgageLenderTestPlaygroundTest(filter: $filter) {
      id
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageLenderTestPlaygroundTest = /* GraphQL */ `
  subscription OnDeleteAdminMortgageLenderTestPlaygroundTest(
    $filter: ModelSubscriptionAdminMortgageLenderTestPlaygroundTestFilterInput
  ) {
    onDeleteAdminMortgageLenderTestPlaygroundTest(filter: $filter) {
      id
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageUploadStatementBasicInfo = /* GraphQL */ `
  subscription OnCreateAdminMortgageUploadStatementBasicInfo(
    $filter: ModelSubscriptionAdminMortgageUploadStatementBasicInfoFilterInput
  ) {
    onCreateAdminMortgageUploadStatementBasicInfo(filter: $filter) {
      id
      institution
      accountType
      accountName
      accountAddress
      friendlyName
      iban
      omit
      numberOfPages
      openingBalance
      closingBalance
      currency
      foreign
      startDate
      endDate
      documentDate
      verifiedData
      error
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageUploadStatementBasicInfo = /* GraphQL */ `
  subscription OnUpdateAdminMortgageUploadStatementBasicInfo(
    $filter: ModelSubscriptionAdminMortgageUploadStatementBasicInfoFilterInput
  ) {
    onUpdateAdminMortgageUploadStatementBasicInfo(filter: $filter) {
      id
      institution
      accountType
      accountName
      accountAddress
      friendlyName
      iban
      omit
      numberOfPages
      openingBalance
      closingBalance
      currency
      foreign
      startDate
      endDate
      documentDate
      verifiedData
      error
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageUploadStatementBasicInfo = /* GraphQL */ `
  subscription OnDeleteAdminMortgageUploadStatementBasicInfo(
    $filter: ModelSubscriptionAdminMortgageUploadStatementBasicInfoFilterInput
  ) {
    onDeleteAdminMortgageUploadStatementBasicInfo(filter: $filter) {
      id
      institution
      accountType
      accountName
      accountAddress
      friendlyName
      iban
      omit
      numberOfPages
      openingBalance
      closingBalance
      currency
      foreign
      startDate
      endDate
      documentDate
      verifiedData
      error
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageSectionVerification = /* GraphQL */ `
  subscription OnCreateAdminMortgageSectionVerification(
    $filter: ModelSubscriptionAdminMortgageSectionVerificationFilterInput
  ) {
    onCreateAdminMortgageSectionVerification(filter: $filter) {
      id
      progressID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageSectionVerification = /* GraphQL */ `
  subscription OnUpdateAdminMortgageSectionVerification(
    $filter: ModelSubscriptionAdminMortgageSectionVerificationFilterInput
  ) {
    onUpdateAdminMortgageSectionVerification(filter: $filter) {
      id
      progressID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageSectionVerification = /* GraphQL */ `
  subscription OnDeleteAdminMortgageSectionVerification(
    $filter: ModelSubscriptionAdminMortgageSectionVerificationFilterInput
  ) {
    onDeleteAdminMortgageSectionVerification(filter: $filter) {
      id
      progressID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageUploadVerifiedPoint = /* GraphQL */ `
  subscription OnCreateAdminMortgageUploadVerifiedPoint(
    $filter: ModelSubscriptionAdminMortgageUploadVerifiedPointFilterInput
  ) {
    onCreateAdminMortgageUploadVerifiedPoint(filter: $filter) {
      id
      uploadID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageUploadVerifiedPoint = /* GraphQL */ `
  subscription OnUpdateAdminMortgageUploadVerifiedPoint(
    $filter: ModelSubscriptionAdminMortgageUploadVerifiedPointFilterInput
  ) {
    onUpdateAdminMortgageUploadVerifiedPoint(filter: $filter) {
      id
      uploadID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageUploadVerifiedPoint = /* GraphQL */ `
  subscription OnDeleteAdminMortgageUploadVerifiedPoint(
    $filter: ModelSubscriptionAdminMortgageUploadVerifiedPointFilterInput
  ) {
    onDeleteAdminMortgageUploadVerifiedPoint(filter: $filter) {
      id
      uploadID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageSectionVerificationNote = /* GraphQL */ `
  subscription OnCreateAdminMortgageSectionVerificationNote(
    $filter: ModelSubscriptionAdminMortgageSectionVerificationNoteFilterInput
  ) {
    onCreateAdminMortgageSectionVerificationNote(filter: $filter) {
      id
      verificationID
      authorID
      string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageSectionVerificationNote = /* GraphQL */ `
  subscription OnUpdateAdminMortgageSectionVerificationNote(
    $filter: ModelSubscriptionAdminMortgageSectionVerificationNoteFilterInput
  ) {
    onUpdateAdminMortgageSectionVerificationNote(filter: $filter) {
      id
      verificationID
      authorID
      string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageSectionVerificationNote = /* GraphQL */ `
  subscription OnDeleteAdminMortgageSectionVerificationNote(
    $filter: ModelSubscriptionAdminMortgageSectionVerificationNoteFilterInput
  ) {
    onDeleteAdminMortgageSectionVerificationNote(filter: $filter) {
      id
      verificationID
      authorID
      string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageSubmission = /* GraphQL */ `
  subscription OnCreateAdminMortgageSubmission(
    $filter: ModelSubscriptionAdminMortgageSubmissionFilterInput
  ) {
    onCreateAdminMortgageSubmission(filter: $filter) {
      id
      mortgageID
      name
      lenderCode
      initialSubmissionTime
      initialSubmissionBy
      initialSubmissionEmailID
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageSubmission = /* GraphQL */ `
  subscription OnUpdateAdminMortgageSubmission(
    $filter: ModelSubscriptionAdminMortgageSubmissionFilterInput
  ) {
    onUpdateAdminMortgageSubmission(filter: $filter) {
      id
      mortgageID
      name
      lenderCode
      initialSubmissionTime
      initialSubmissionBy
      initialSubmissionEmailID
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageSubmission = /* GraphQL */ `
  subscription OnDeleteAdminMortgageSubmission(
    $filter: ModelSubscriptionAdminMortgageSubmissionFilterInput
  ) {
    onDeleteAdminMortgageSubmission(filter: $filter) {
      id
      mortgageID
      name
      lenderCode
      initialSubmissionTime
      initialSubmissionBy
      initialSubmissionEmailID
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageSubmissionIssue = /* GraphQL */ `
  subscription OnCreateAdminMortgageSubmissionIssue(
    $filter: ModelSubscriptionAdminMortgageSubmissionIssueFilterInput
  ) {
    onCreateAdminMortgageSubmissionIssue(filter: $filter) {
      id
      submissionID
      emailID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageSubmissionIssue = /* GraphQL */ `
  subscription OnUpdateAdminMortgageSubmissionIssue(
    $filter: ModelSubscriptionAdminMortgageSubmissionIssueFilterInput
  ) {
    onUpdateAdminMortgageSubmissionIssue(filter: $filter) {
      id
      submissionID
      emailID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageSubmissionIssue = /* GraphQL */ `
  subscription OnDeleteAdminMortgageSubmissionIssue(
    $filter: ModelSubscriptionAdminMortgageSubmissionIssueFilterInput
  ) {
    onDeleteAdminMortgageSubmissionIssue(filter: $filter) {
      id
      submissionID
      emailID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminMortgageProtectionInsurance = /* GraphQL */ `
  subscription OnCreateAdminMortgageProtectionInsurance(
    $filter: ModelSubscriptionAdminMortgageProtectionInsuranceFilterInput
  ) {
    onCreateAdminMortgageProtectionInsurance(filter: $filter) {
      id
      mortgageID
      provider
      quoteGenerated
      quoteGeneratedOn
      quoteGeneratedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminMortgageProtectionInsurance = /* GraphQL */ `
  subscription OnUpdateAdminMortgageProtectionInsurance(
    $filter: ModelSubscriptionAdminMortgageProtectionInsuranceFilterInput
  ) {
    onUpdateAdminMortgageProtectionInsurance(filter: $filter) {
      id
      mortgageID
      provider
      quoteGenerated
      quoteGeneratedOn
      quoteGeneratedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminMortgageProtectionInsurance = /* GraphQL */ `
  subscription OnDeleteAdminMortgageProtectionInsurance(
    $filter: ModelSubscriptionAdminMortgageProtectionInsuranceFilterInput
  ) {
    onDeleteAdminMortgageProtectionInsurance(filter: $filter) {
      id
      mortgageID
      provider
      quoteGenerated
      quoteGeneratedOn
      quoteGeneratedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserPdfId = /* GraphQL */ `
  subscription OnCreateUserPdfId(
    $filter: ModelSubscriptionUserPdfIdFilterInput
  ) {
    onCreateUserPdfId(filter: $filter) {
      id
      pdfType
      institution
      friendlyName
      subType
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserPdfId = /* GraphQL */ `
  subscription OnUpdateUserPdfId(
    $filter: ModelSubscriptionUserPdfIdFilterInput
  ) {
    onUpdateUserPdfId(filter: $filter) {
      id
      pdfType
      institution
      friendlyName
      subType
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserPdfId = /* GraphQL */ `
  subscription OnDeleteUserPdfId(
    $filter: ModelSubscriptionUserPdfIdFilterInput
  ) {
    onDeleteUserPdfId(filter: $filter) {
      id
      pdfType
      institution
      friendlyName
      subType
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserMortgageAppointment = /* GraphQL */ `
  subscription OnCreateUserMortgageAppointment(
    $filter: ModelSubscriptionUserMortgageAppointmentFilterInput
    $owner: String
  ) {
    onCreateUserMortgageAppointment(filter: $filter, owner: $owner) {
      id
      backendUserID
      appointmentType
      expired
      appointmentDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageAppointment = /* GraphQL */ `
  subscription OnUpdateUserMortgageAppointment(
    $filter: ModelSubscriptionUserMortgageAppointmentFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageAppointment(filter: $filter, owner: $owner) {
      id
      backendUserID
      appointmentType
      expired
      appointmentDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageAppointment = /* GraphQL */ `
  subscription OnDeleteUserMortgageAppointment(
    $filter: ModelSubscriptionUserMortgageAppointmentFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageAppointment(filter: $filter, owner: $owner) {
      id
      backendUserID
      appointmentType
      expired
      appointmentDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageRecommendation = /* GraphQL */ `
  subscription OnCreateUserMortgageRecommendation(
    $filter: ModelSubscriptionUserMortgageRecommendationFilterInput
    $owner: String
  ) {
    onCreateUserMortgageRecommendation(filter: $filter, owner: $owner) {
      id
      lenderCode
      rate
      term
      loan
      value
      notes
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageRecommendation = /* GraphQL */ `
  subscription OnUpdateUserMortgageRecommendation(
    $filter: ModelSubscriptionUserMortgageRecommendationFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageRecommendation(filter: $filter, owner: $owner) {
      id
      lenderCode
      rate
      term
      loan
      value
      notes
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageRecommendation = /* GraphQL */ `
  subscription OnDeleteUserMortgageRecommendation(
    $filter: ModelSubscriptionUserMortgageRecommendationFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageRecommendation(filter: $filter, owner: $owner) {
      id
      lenderCode
      rate
      term
      loan
      value
      notes
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageQuery = /* GraphQL */ `
  subscription OnCreateUserMortgageQuery(
    $filter: ModelSubscriptionUserMortgageQueryFilterInput
    $owner: String
  ) {
    onCreateUserMortgageQuery(filter: $filter, owner: $owner) {
      id
      mortgageID
      emailReplyID
      createdBy
      referenceType
      referenceId
      stage
      section
      index
      title
      date
      description
      amount
      query
      userAnswer
      userAnswerOn
      userAnswerBy
      userAnswered
      adminAnswered
      lenderAnswer
      lenderAnswerOn
      lenderAnswerBy
      excludedOn
      excludedBy
      private
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageQuery = /* GraphQL */ `
  subscription OnUpdateUserMortgageQuery(
    $filter: ModelSubscriptionUserMortgageQueryFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageQuery(filter: $filter, owner: $owner) {
      id
      mortgageID
      emailReplyID
      createdBy
      referenceType
      referenceId
      stage
      section
      index
      title
      date
      description
      amount
      query
      userAnswer
      userAnswerOn
      userAnswerBy
      userAnswered
      adminAnswered
      lenderAnswer
      lenderAnswerOn
      lenderAnswerBy
      excludedOn
      excludedBy
      private
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageQuery = /* GraphQL */ `
  subscription OnDeleteUserMortgageQuery(
    $filter: ModelSubscriptionUserMortgageQueryFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageQuery(filter: $filter, owner: $owner) {
      id
      mortgageID
      emailReplyID
      createdBy
      referenceType
      referenceId
      stage
      section
      index
      title
      date
      description
      amount
      query
      userAnswer
      userAnswerOn
      userAnswerBy
      userAnswered
      adminAnswered
      lenderAnswer
      lenderAnswerOn
      lenderAnswerBy
      excludedOn
      excludedBy
      private
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgage = /* GraphQL */ `
  subscription OnCreateUserMortgage(
    $filter: ModelSubscriptionUserMortgageFilterInput
    $owner: String
  ) {
    onCreateUserMortgage(filter: $filter, owner: $owner) {
      id
      twoApplicants
      mortgageType
      applicationType
      subs
      data
      activeAgent
      recommendations {
        items {
          id
          lenderCode
          rate
          term
          loan
          value
          notes
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      appointments {
        items {
          id
          backendUserID
          appointmentType
          expired
          appointmentDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          replyTo
          requestType
          requestBody
          itemsLoaded
          noteBody
          assocEmails
          queries {
            items {
              id
              mortgageID
              emailReplyID
              createdBy
              referenceType
              referenceId
              stage
              section
              index
              title
              date
              description
              amount
              query
              userAnswer
              userAnswerOn
              userAnswerBy
              userAnswered
              adminAnswered
              lenderAnswer
              lenderAnswerOn
              lenderAnswerBy
              excludedOn
              excludedBy
              private
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tasks {
            items {
              id
              sectionStage
              sectionType
              sectionName
              sectionIndex
              sectionTitle
              sectionApplicant
              sectionInstructions
              mortgageID
              emailReplyID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1ID
      applicant2ID
      lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requirement {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              lenderCode
              promotions
              conditions
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      properties {
        items {
          id
          addressKnown
          address
          propertyType
          buildType
          newlyBuilt
          bedrooms
          bathrooms
          berScore
          propertyValue
          propertyPrice
          siteOwned
          siteValue
          constructionCosts
          constructionStarted
          origPurchasePrice
          yearOfPurchase
          residingHere
          adultsBoarding
          adultsBoardingCount
          isRented
          monthlyRent
          isMortgaged
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          bookingDepositPaid
          contractDepositPaid
          reinstatementValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          surveyRequired
          surveyDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      uploads {
        items {
          id
          contentType
          fileName
          logicalName
          basicInfo {
            id
            institution
            accountType
            accountName
            accountAddress
            friendlyName
            iban
            omit
            numberOfPages
            openingBalance
            closingBalance
            currency
            foreign
            startDate
            endDate
            documentDate
            verifiedData
            error
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          verifiedData {
            items {
              id
              uploadID
              mortgageID
              name
              verifiedValue
              verifiedValueType
              verificationOn
              verificationBy
              supervisorCheckRequired
              supervisorCheckOn
              supervisorCheckBy
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          fileSize
          fileExtension
          stage
          section
          index
          analysis
          status
          statusBy
          statusOn
          mortgageID
          observeMID
          reason
          identityID
          stampedOn
          seenOn
          seenBy
          omit
          documentDate
          exclude
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      progress {
        items {
          id
          applicationStage
          sectionType
          sectionName
          sectionIndex
          percent
          mortgageID
          percentUpdatedAt
          alertTitle
          alertBody
          alertAuthor
          alertDismissed
          alertDone
          alertPending
          lastDocumentDate
          isNewAccount
          metaData
          verifiedData
          sectionChecked
          sectionCheckedBy
          expiresDate
          note
          internalNote
          internalNoteBy
          internalNoteOn
          submittedOn
          submittedBy
          outOfDate
          arrangement
          excludeFromCalculations
          notes {
            items {
              id
              text
              readBy
              readTime
              userMortgageProgressID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicationStage
      applicationFutureDate
      applicationStatus
      notes {
        items {
          id
          body
          author
          pinned
          deleted
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      dynamicSections {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          body
          author
          pinned
          due
          deleted
          target
          targetStarted
          targetLastActive
          completedOn
          completedBy
          paused
          closedOn
          closedBy
          folder
          userFolder
          userSortPosition
          tags
          currentOwner
          lastMessageBy
          lastMessageOn
          lastMessage
          toDos {
            items {
              id
              taskID
              completedOn
              completedBy
              toDoItem
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          pauses
          components {
            items {
              id
              taskID
              component
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stageTasks {
        items {
          id
          mortgageID
          stage
          name
          deleted
          due
          completedOn
          completedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitations {
        items {
          id
          mortgageID
          applicant2ID
          firstName
          email
          inviter
          inviterFirstName
          sent
          accepted
          invalidated
          inviteType
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      docContents {
        items {
          id
          mortgageID
          paragraph
          folder
          document
          sequence
          html
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastSeen
      lastFlowItem
      lastFlowItemTime
      completedFlowItems
      qualification
      qualificationConfirmed
      adminMessage
      adminMessageBy
      adminMessageOn
      advisorID
      caseNumber
      friendlyName
      UserMortgageDocuments {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notifications {
        items {
          id
          mortgageID
          stage
          name
          history {
            items {
              id
              sentTime
              sentBy
              data
              notificationId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userMortgageRequirementId
      userMortgagePropertyId
      userMortgageExistingId
      userMortgageNeedsId
      userMortgageSettingsId
      userMortgageSolicitorId
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgage = /* GraphQL */ `
  subscription OnUpdateUserMortgage(
    $filter: ModelSubscriptionUserMortgageFilterInput
    $owner: String
  ) {
    onUpdateUserMortgage(filter: $filter, owner: $owner) {
      id
      twoApplicants
      mortgageType
      applicationType
      subs
      data
      activeAgent
      recommendations {
        items {
          id
          lenderCode
          rate
          term
          loan
          value
          notes
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      appointments {
        items {
          id
          backendUserID
          appointmentType
          expired
          appointmentDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          replyTo
          requestType
          requestBody
          itemsLoaded
          noteBody
          assocEmails
          queries {
            items {
              id
              mortgageID
              emailReplyID
              createdBy
              referenceType
              referenceId
              stage
              section
              index
              title
              date
              description
              amount
              query
              userAnswer
              userAnswerOn
              userAnswerBy
              userAnswered
              adminAnswered
              lenderAnswer
              lenderAnswerOn
              lenderAnswerBy
              excludedOn
              excludedBy
              private
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tasks {
            items {
              id
              sectionStage
              sectionType
              sectionName
              sectionIndex
              sectionTitle
              sectionApplicant
              sectionInstructions
              mortgageID
              emailReplyID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1ID
      applicant2ID
      lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requirement {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              lenderCode
              promotions
              conditions
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      properties {
        items {
          id
          addressKnown
          address
          propertyType
          buildType
          newlyBuilt
          bedrooms
          bathrooms
          berScore
          propertyValue
          propertyPrice
          siteOwned
          siteValue
          constructionCosts
          constructionStarted
          origPurchasePrice
          yearOfPurchase
          residingHere
          adultsBoarding
          adultsBoardingCount
          isRented
          monthlyRent
          isMortgaged
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          bookingDepositPaid
          contractDepositPaid
          reinstatementValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          surveyRequired
          surveyDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      uploads {
        items {
          id
          contentType
          fileName
          logicalName
          basicInfo {
            id
            institution
            accountType
            accountName
            accountAddress
            friendlyName
            iban
            omit
            numberOfPages
            openingBalance
            closingBalance
            currency
            foreign
            startDate
            endDate
            documentDate
            verifiedData
            error
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          verifiedData {
            items {
              id
              uploadID
              mortgageID
              name
              verifiedValue
              verifiedValueType
              verificationOn
              verificationBy
              supervisorCheckRequired
              supervisorCheckOn
              supervisorCheckBy
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          fileSize
          fileExtension
          stage
          section
          index
          analysis
          status
          statusBy
          statusOn
          mortgageID
          observeMID
          reason
          identityID
          stampedOn
          seenOn
          seenBy
          omit
          documentDate
          exclude
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      progress {
        items {
          id
          applicationStage
          sectionType
          sectionName
          sectionIndex
          percent
          mortgageID
          percentUpdatedAt
          alertTitle
          alertBody
          alertAuthor
          alertDismissed
          alertDone
          alertPending
          lastDocumentDate
          isNewAccount
          metaData
          verifiedData
          sectionChecked
          sectionCheckedBy
          expiresDate
          note
          internalNote
          internalNoteBy
          internalNoteOn
          submittedOn
          submittedBy
          outOfDate
          arrangement
          excludeFromCalculations
          notes {
            items {
              id
              text
              readBy
              readTime
              userMortgageProgressID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicationStage
      applicationFutureDate
      applicationStatus
      notes {
        items {
          id
          body
          author
          pinned
          deleted
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      dynamicSections {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          body
          author
          pinned
          due
          deleted
          target
          targetStarted
          targetLastActive
          completedOn
          completedBy
          paused
          closedOn
          closedBy
          folder
          userFolder
          userSortPosition
          tags
          currentOwner
          lastMessageBy
          lastMessageOn
          lastMessage
          toDos {
            items {
              id
              taskID
              completedOn
              completedBy
              toDoItem
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          pauses
          components {
            items {
              id
              taskID
              component
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stageTasks {
        items {
          id
          mortgageID
          stage
          name
          deleted
          due
          completedOn
          completedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitations {
        items {
          id
          mortgageID
          applicant2ID
          firstName
          email
          inviter
          inviterFirstName
          sent
          accepted
          invalidated
          inviteType
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      docContents {
        items {
          id
          mortgageID
          paragraph
          folder
          document
          sequence
          html
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastSeen
      lastFlowItem
      lastFlowItemTime
      completedFlowItems
      qualification
      qualificationConfirmed
      adminMessage
      adminMessageBy
      adminMessageOn
      advisorID
      caseNumber
      friendlyName
      UserMortgageDocuments {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notifications {
        items {
          id
          mortgageID
          stage
          name
          history {
            items {
              id
              sentTime
              sentBy
              data
              notificationId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userMortgageRequirementId
      userMortgagePropertyId
      userMortgageExistingId
      userMortgageNeedsId
      userMortgageSettingsId
      userMortgageSolicitorId
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgage = /* GraphQL */ `
  subscription OnDeleteUserMortgage(
    $filter: ModelSubscriptionUserMortgageFilterInput
    $owner: String
  ) {
    onDeleteUserMortgage(filter: $filter, owner: $owner) {
      id
      twoApplicants
      mortgageType
      applicationType
      subs
      data
      activeAgent
      recommendations {
        items {
          id
          lenderCode
          rate
          term
          loan
          value
          notes
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      appointments {
        items {
          id
          backendUserID
          appointmentType
          expired
          appointmentDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          replyTo
          requestType
          requestBody
          itemsLoaded
          noteBody
          assocEmails
          queries {
            items {
              id
              mortgageID
              emailReplyID
              createdBy
              referenceType
              referenceId
              stage
              section
              index
              title
              date
              description
              amount
              query
              userAnswer
              userAnswerOn
              userAnswerBy
              userAnswered
              adminAnswered
              lenderAnswer
              lenderAnswerOn
              lenderAnswerBy
              excludedOn
              excludedBy
              private
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tasks {
            items {
              id
              sectionStage
              sectionType
              sectionName
              sectionIndex
              sectionTitle
              sectionApplicant
              sectionInstructions
              mortgageID
              emailReplyID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1ID
      applicant2ID
      lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requirement {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              lenderCode
              promotions
              conditions
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      properties {
        items {
          id
          addressKnown
          address
          propertyType
          buildType
          newlyBuilt
          bedrooms
          bathrooms
          berScore
          propertyValue
          propertyPrice
          siteOwned
          siteValue
          constructionCosts
          constructionStarted
          origPurchasePrice
          yearOfPurchase
          residingHere
          adultsBoarding
          adultsBoardingCount
          isRented
          monthlyRent
          isMortgaged
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          bookingDepositPaid
          contractDepositPaid
          reinstatementValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          surveyRequired
          surveyDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      uploads {
        items {
          id
          contentType
          fileName
          logicalName
          basicInfo {
            id
            institution
            accountType
            accountName
            accountAddress
            friendlyName
            iban
            omit
            numberOfPages
            openingBalance
            closingBalance
            currency
            foreign
            startDate
            endDate
            documentDate
            verifiedData
            error
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          verifiedData {
            items {
              id
              uploadID
              mortgageID
              name
              verifiedValue
              verifiedValueType
              verificationOn
              verificationBy
              supervisorCheckRequired
              supervisorCheckOn
              supervisorCheckBy
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          fileSize
          fileExtension
          stage
          section
          index
          analysis
          status
          statusBy
          statusOn
          mortgageID
          observeMID
          reason
          identityID
          stampedOn
          seenOn
          seenBy
          omit
          documentDate
          exclude
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      progress {
        items {
          id
          applicationStage
          sectionType
          sectionName
          sectionIndex
          percent
          mortgageID
          percentUpdatedAt
          alertTitle
          alertBody
          alertAuthor
          alertDismissed
          alertDone
          alertPending
          lastDocumentDate
          isNewAccount
          metaData
          verifiedData
          sectionChecked
          sectionCheckedBy
          expiresDate
          note
          internalNote
          internalNoteBy
          internalNoteOn
          submittedOn
          submittedBy
          outOfDate
          arrangement
          excludeFromCalculations
          notes {
            items {
              id
              text
              readBy
              readTime
              userMortgageProgressID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicationStage
      applicationFutureDate
      applicationStatus
      notes {
        items {
          id
          body
          author
          pinned
          deleted
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      dynamicSections {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          body
          author
          pinned
          due
          deleted
          target
          targetStarted
          targetLastActive
          completedOn
          completedBy
          paused
          closedOn
          closedBy
          folder
          userFolder
          userSortPosition
          tags
          currentOwner
          lastMessageBy
          lastMessageOn
          lastMessage
          toDos {
            items {
              id
              taskID
              completedOn
              completedBy
              toDoItem
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          pauses
          components {
            items {
              id
              taskID
              component
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stageTasks {
        items {
          id
          mortgageID
          stage
          name
          deleted
          due
          completedOn
          completedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitations {
        items {
          id
          mortgageID
          applicant2ID
          firstName
          email
          inviter
          inviterFirstName
          sent
          accepted
          invalidated
          inviteType
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      docContents {
        items {
          id
          mortgageID
          paragraph
          folder
          document
          sequence
          html
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastSeen
      lastFlowItem
      lastFlowItemTime
      completedFlowItems
      qualification
      qualificationConfirmed
      adminMessage
      adminMessageBy
      adminMessageOn
      advisorID
      caseNumber
      friendlyName
      UserMortgageDocuments {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notifications {
        items {
          id
          mortgageID
          stage
          name
          history {
            items {
              id
              sentTime
              sentBy
              data
              notificationId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userMortgageRequirementId
      userMortgagePropertyId
      userMortgageExistingId
      userMortgageNeedsId
      userMortgageSettingsId
      userMortgageSolicitorId
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageInvitation = /* GraphQL */ `
  subscription OnCreateUserMortgageInvitation(
    $filter: ModelSubscriptionUserMortgageInvitationFilterInput
    $owner: String
  ) {
    onCreateUserMortgageInvitation(filter: $filter, owner: $owner) {
      id
      mortgageID
      applicant2ID
      firstName
      email
      inviter
      inviterFirstName
      sent
      accepted
      invalidated
      inviteType
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageInvitation = /* GraphQL */ `
  subscription OnUpdateUserMortgageInvitation(
    $filter: ModelSubscriptionUserMortgageInvitationFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageInvitation(filter: $filter, owner: $owner) {
      id
      mortgageID
      applicant2ID
      firstName
      email
      inviter
      inviterFirstName
      sent
      accepted
      invalidated
      inviteType
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageInvitation = /* GraphQL */ `
  subscription OnDeleteUserMortgageInvitation(
    $filter: ModelSubscriptionUserMortgageInvitationFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageInvitation(filter: $filter, owner: $owner) {
      id
      mortgageID
      applicant2ID
      firstName
      email
      inviter
      inviterFirstName
      sent
      accepted
      invalidated
      inviteType
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageDocuments = /* GraphQL */ `
  subscription OnCreateUserMortgageDocuments(
    $filter: ModelSubscriptionUserMortgageDocumentsFilterInput
    $owner: String
  ) {
    onCreateUserMortgageDocuments(filter: $filter, owner: $owner) {
      id
      title
      description
      identityID
      identityID2
      folder
      fileName
      fileExtension
      isDynamic
      name
      stage
      private
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageDocuments = /* GraphQL */ `
  subscription OnUpdateUserMortgageDocuments(
    $filter: ModelSubscriptionUserMortgageDocumentsFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageDocuments(filter: $filter, owner: $owner) {
      id
      title
      description
      identityID
      identityID2
      folder
      fileName
      fileExtension
      isDynamic
      name
      stage
      private
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageDocuments = /* GraphQL */ `
  subscription OnDeleteUserMortgageDocuments(
    $filter: ModelSubscriptionUserMortgageDocumentsFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageDocuments(filter: $filter, owner: $owner) {
      id
      title
      description
      identityID
      identityID2
      folder
      fileName
      fileExtension
      isDynamic
      name
      stage
      private
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageProgress = /* GraphQL */ `
  subscription OnCreateUserMortgageProgress(
    $filter: ModelSubscriptionUserMortgageProgressFilterInput
    $owner: String
  ) {
    onCreateUserMortgageProgress(filter: $filter, owner: $owner) {
      id
      applicationStage
      sectionType
      sectionName
      sectionIndex
      percent
      mortgageID
      percentUpdatedAt
      alertTitle
      alertBody
      alertAuthor
      alertDismissed
      alertDone
      alertPending
      lastDocumentDate
      isNewAccount
      metaData
      verifiedData
      sectionChecked
      sectionCheckedBy
      expiresDate
      note
      internalNote
      internalNoteBy
      internalNoteOn
      submittedOn
      submittedBy
      outOfDate
      arrangement
      excludeFromCalculations
      notes {
        items {
          id
          text
          readBy
          readTime
          userMortgageProgressID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageProgress = /* GraphQL */ `
  subscription OnUpdateUserMortgageProgress(
    $filter: ModelSubscriptionUserMortgageProgressFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageProgress(filter: $filter, owner: $owner) {
      id
      applicationStage
      sectionType
      sectionName
      sectionIndex
      percent
      mortgageID
      percentUpdatedAt
      alertTitle
      alertBody
      alertAuthor
      alertDismissed
      alertDone
      alertPending
      lastDocumentDate
      isNewAccount
      metaData
      verifiedData
      sectionChecked
      sectionCheckedBy
      expiresDate
      note
      internalNote
      internalNoteBy
      internalNoteOn
      submittedOn
      submittedBy
      outOfDate
      arrangement
      excludeFromCalculations
      notes {
        items {
          id
          text
          readBy
          readTime
          userMortgageProgressID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageProgress = /* GraphQL */ `
  subscription OnDeleteUserMortgageProgress(
    $filter: ModelSubscriptionUserMortgageProgressFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageProgress(filter: $filter, owner: $owner) {
      id
      applicationStage
      sectionType
      sectionName
      sectionIndex
      percent
      mortgageID
      percentUpdatedAt
      alertTitle
      alertBody
      alertAuthor
      alertDismissed
      alertDone
      alertPending
      lastDocumentDate
      isNewAccount
      metaData
      verifiedData
      sectionChecked
      sectionCheckedBy
      expiresDate
      note
      internalNote
      internalNoteBy
      internalNoteOn
      submittedOn
      submittedBy
      outOfDate
      arrangement
      excludeFromCalculations
      notes {
        items {
          id
          text
          readBy
          readTime
          userMortgageProgressID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageProgressNotes = /* GraphQL */ `
  subscription OnCreateUserMortgageProgressNotes(
    $filter: ModelSubscriptionUserMortgageProgressNotesFilterInput
    $owner: String
  ) {
    onCreateUserMortgageProgressNotes(filter: $filter, owner: $owner) {
      id
      text
      readBy
      readTime
      userMortgageProgressID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageProgressNotes = /* GraphQL */ `
  subscription OnUpdateUserMortgageProgressNotes(
    $filter: ModelSubscriptionUserMortgageProgressNotesFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageProgressNotes(filter: $filter, owner: $owner) {
      id
      text
      readBy
      readTime
      userMortgageProgressID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageProgressNotes = /* GraphQL */ `
  subscription OnDeleteUserMortgageProgressNotes(
    $filter: ModelSubscriptionUserMortgageProgressNotesFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageProgressNotes(filter: $filter, owner: $owner) {
      id
      text
      readBy
      readTime
      userMortgageProgressID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageDynamicSection = /* GraphQL */ `
  subscription OnCreateUserMortgageDynamicSection(
    $filter: ModelSubscriptionUserMortgageDynamicSectionFilterInput
    $owner: String
  ) {
    onCreateUserMortgageDynamicSection(filter: $filter, owner: $owner) {
      id
      sectionStage
      sectionType
      sectionName
      sectionIndex
      sectionTitle
      sectionApplicant
      sectionInstructions
      mortgageID
      emailReplyID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageDynamicSection = /* GraphQL */ `
  subscription OnUpdateUserMortgageDynamicSection(
    $filter: ModelSubscriptionUserMortgageDynamicSectionFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageDynamicSection(filter: $filter, owner: $owner) {
      id
      sectionStage
      sectionType
      sectionName
      sectionIndex
      sectionTitle
      sectionApplicant
      sectionInstructions
      mortgageID
      emailReplyID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageDynamicSection = /* GraphQL */ `
  subscription OnDeleteUserMortgageDynamicSection(
    $filter: ModelSubscriptionUserMortgageDynamicSectionFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageDynamicSection(filter: $filter, owner: $owner) {
      id
      sectionStage
      sectionType
      sectionName
      sectionIndex
      sectionTitle
      sectionApplicant
      sectionInstructions
      mortgageID
      emailReplyID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageUploads = /* GraphQL */ `
  subscription OnCreateUserMortgageUploads(
    $filter: ModelSubscriptionUserMortgageUploadsFilterInput
    $owner: String
  ) {
    onCreateUserMortgageUploads(filter: $filter, owner: $owner) {
      id
      contentType
      fileName
      logicalName
      basicInfo {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      fileSize
      fileExtension
      stage
      section
      index
      analysis
      status
      statusBy
      statusOn
      mortgageID
      observeMID
      reason
      identityID
      stampedOn
      seenOn
      seenBy
      omit
      documentDate
      exclude
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageUploads = /* GraphQL */ `
  subscription OnUpdateUserMortgageUploads(
    $filter: ModelSubscriptionUserMortgageUploadsFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageUploads(filter: $filter, owner: $owner) {
      id
      contentType
      fileName
      logicalName
      basicInfo {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      fileSize
      fileExtension
      stage
      section
      index
      analysis
      status
      statusBy
      statusOn
      mortgageID
      observeMID
      reason
      identityID
      stampedOn
      seenOn
      seenBy
      omit
      documentDate
      exclude
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageUploads = /* GraphQL */ `
  subscription OnDeleteUserMortgageUploads(
    $filter: ModelSubscriptionUserMortgageUploadsFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageUploads(filter: $filter, owner: $owner) {
      id
      contentType
      fileName
      logicalName
      basicInfo {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      fileSize
      fileExtension
      stage
      section
      index
      analysis
      status
      statusBy
      statusOn
      mortgageID
      observeMID
      reason
      identityID
      stampedOn
      seenOn
      seenBy
      omit
      documentDate
      exclude
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageRequirement = /* GraphQL */ `
  subscription OnCreateUserMortgageRequirement(
    $filter: ModelSubscriptionUserMortgageRequirementFilterInput
    $owner: String
  ) {
    onCreateUserMortgageRequirement(filter: $filter, owner: $owner) {
      id
      applicationElsewhere
      loanRequired
      cohabiting
      loanTerm
      rateId
      rate
      monthlyPayment
      finalRate
      finalTerm
      firstTimeBuyers
      combinedDependants
      combinedGrossEarnings
      combinedLoanRepayments
      depositSavings
      depositGifts
      depositPaid
      helpToBuyScheme
      helpToBuySchemeAmount
      helpToBuySchemeAccessCode
      helpToBuySchemeApplicationCode
      helpToBuySchemeDeveloperName
      firstHomeScheme
      firstHomeSchemeCouncil
      firstHomeSchemeType
      firstHomeSchemeAmount
      lenderId
      lender {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            lenderCode
            promotions
            conditions
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lenderCode
      lenderProduct
      contactMethod
      proposals
      soft
      drawdownDate
      aipExpiryDate
      aipDate
      loanOfferDate
      loanOfferExpiryDate
      ddBankAccountID
      mortgageAccountNo
      propertyReference
      price
      hasLoans
      hasCards
      receivingGifts
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageRequirement = /* GraphQL */ `
  subscription OnUpdateUserMortgageRequirement(
    $filter: ModelSubscriptionUserMortgageRequirementFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageRequirement(filter: $filter, owner: $owner) {
      id
      applicationElsewhere
      loanRequired
      cohabiting
      loanTerm
      rateId
      rate
      monthlyPayment
      finalRate
      finalTerm
      firstTimeBuyers
      combinedDependants
      combinedGrossEarnings
      combinedLoanRepayments
      depositSavings
      depositGifts
      depositPaid
      helpToBuyScheme
      helpToBuySchemeAmount
      helpToBuySchemeAccessCode
      helpToBuySchemeApplicationCode
      helpToBuySchemeDeveloperName
      firstHomeScheme
      firstHomeSchemeCouncil
      firstHomeSchemeType
      firstHomeSchemeAmount
      lenderId
      lender {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            lenderCode
            promotions
            conditions
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lenderCode
      lenderProduct
      contactMethod
      proposals
      soft
      drawdownDate
      aipExpiryDate
      aipDate
      loanOfferDate
      loanOfferExpiryDate
      ddBankAccountID
      mortgageAccountNo
      propertyReference
      price
      hasLoans
      hasCards
      receivingGifts
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageRequirement = /* GraphQL */ `
  subscription OnDeleteUserMortgageRequirement(
    $filter: ModelSubscriptionUserMortgageRequirementFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageRequirement(filter: $filter, owner: $owner) {
      id
      applicationElsewhere
      loanRequired
      cohabiting
      loanTerm
      rateId
      rate
      monthlyPayment
      finalRate
      finalTerm
      firstTimeBuyers
      combinedDependants
      combinedGrossEarnings
      combinedLoanRepayments
      depositSavings
      depositGifts
      depositPaid
      helpToBuyScheme
      helpToBuySchemeAmount
      helpToBuySchemeAccessCode
      helpToBuySchemeApplicationCode
      helpToBuySchemeDeveloperName
      firstHomeScheme
      firstHomeSchemeCouncil
      firstHomeSchemeType
      firstHomeSchemeAmount
      lenderId
      lender {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            lenderCode
            promotions
            conditions
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lenderCode
      lenderProduct
      contactMethod
      proposals
      soft
      drawdownDate
      aipExpiryDate
      aipDate
      loanOfferDate
      loanOfferExpiryDate
      ddBankAccountID
      mortgageAccountNo
      propertyReference
      price
      hasLoans
      hasCards
      receivingGifts
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageProperty = /* GraphQL */ `
  subscription OnCreateUserMortgageProperty(
    $filter: ModelSubscriptionUserMortgagePropertyFilterInput
    $owner: String
  ) {
    onCreateUserMortgageProperty(filter: $filter, owner: $owner) {
      id
      intendedUse
      addressKnown
      propertyType
      address
      newlyBuilt
      bedrooms
      bathrooms
      spendAmount
      purchasePrice
      propertyValue
      monthlyRent
      residingHere
      yearOfPurchase
      adultsBoarding
      adultsBoardingCount
      berScore
      purchaseStage
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageProperty = /* GraphQL */ `
  subscription OnUpdateUserMortgageProperty(
    $filter: ModelSubscriptionUserMortgagePropertyFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageProperty(filter: $filter, owner: $owner) {
      id
      intendedUse
      addressKnown
      propertyType
      address
      newlyBuilt
      bedrooms
      bathrooms
      spendAmount
      purchasePrice
      propertyValue
      monthlyRent
      residingHere
      yearOfPurchase
      adultsBoarding
      adultsBoardingCount
      berScore
      purchaseStage
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageProperty = /* GraphQL */ `
  subscription OnDeleteUserMortgageProperty(
    $filter: ModelSubscriptionUserMortgagePropertyFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageProperty(filter: $filter, owner: $owner) {
      id
      intendedUse
      addressKnown
      propertyType
      address
      newlyBuilt
      bedrooms
      bathrooms
      spendAmount
      purchasePrice
      propertyValue
      monthlyRent
      residingHere
      yearOfPurchase
      adultsBoarding
      adultsBoardingCount
      berScore
      purchaseStage
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageExisting = /* GraphQL */ `
  subscription OnCreateUserMortgageExisting(
    $filter: ModelSubscriptionUserMortgageExistingFilterInput
    $owner: String
  ) {
    onCreateUserMortgageExisting(filter: $filter, owner: $owner) {
      id
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      topUpRequired
      topUpPurpose
      topUpAmount
      readAccessGroup
      editAccessGroup
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageExisting = /* GraphQL */ `
  subscription OnUpdateUserMortgageExisting(
    $filter: ModelSubscriptionUserMortgageExistingFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageExisting(filter: $filter, owner: $owner) {
      id
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      topUpRequired
      topUpPurpose
      topUpAmount
      readAccessGroup
      editAccessGroup
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageExisting = /* GraphQL */ `
  subscription OnDeleteUserMortgageExisting(
    $filter: ModelSubscriptionUserMortgageExistingFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageExisting(filter: $filter, owner: $owner) {
      id
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      topUpRequired
      topUpPurpose
      topUpAmount
      readAccessGroup
      editAccessGroup
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageNeeds = /* GraphQL */ `
  subscription OnCreateUserMortgageNeeds(
    $filter: ModelSubscriptionUserMortgageNeedsFilterInput
    $owner: String
  ) {
    onCreateUserMortgageNeeds(filter: $filter, owner: $owner) {
      id
      circumstanceChanges
      circumstanceChangesDetails
      healthIssues
      healthIssuesDetails
      policeRecord
      policeRecordDetails
      incomeChange
      expenditureChange
      moveLikely
      plannedPayOff
      beyondRetirement
      speedEssential
      consolidatingDebt
      furtherRequirements
      furtherRequirementsDetails
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageNeeds = /* GraphQL */ `
  subscription OnUpdateUserMortgageNeeds(
    $filter: ModelSubscriptionUserMortgageNeedsFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageNeeds(filter: $filter, owner: $owner) {
      id
      circumstanceChanges
      circumstanceChangesDetails
      healthIssues
      healthIssuesDetails
      policeRecord
      policeRecordDetails
      incomeChange
      expenditureChange
      moveLikely
      plannedPayOff
      beyondRetirement
      speedEssential
      consolidatingDebt
      furtherRequirements
      furtherRequirementsDetails
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageNeeds = /* GraphQL */ `
  subscription OnDeleteUserMortgageNeeds(
    $filter: ModelSubscriptionUserMortgageNeedsFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageNeeds(filter: $filter, owner: $owner) {
      id
      circumstanceChanges
      circumstanceChangesDetails
      healthIssues
      healthIssuesDetails
      policeRecord
      policeRecordDetails
      incomeChange
      expenditureChange
      moveLikely
      plannedPayOff
      beyondRetirement
      speedEssential
      consolidatingDebt
      furtherRequirements
      furtherRequirementsDetails
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgageSolicitor = /* GraphQL */ `
  subscription OnCreateUserMortgageSolicitor(
    $filter: ModelSubscriptionUserMortgageSolicitorFilterInput
    $owner: String
  ) {
    onCreateUserMortgageSolicitor(filter: $filter, owner: $owner) {
      id
      firmName
      firmAddress
      contactName
      contactEmail
      contactPhone
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgageSolicitor = /* GraphQL */ `
  subscription OnUpdateUserMortgageSolicitor(
    $filter: ModelSubscriptionUserMortgageSolicitorFilterInput
    $owner: String
  ) {
    onUpdateUserMortgageSolicitor(filter: $filter, owner: $owner) {
      id
      firmName
      firmAddress
      contactName
      contactEmail
      contactPhone
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgageSolicitor = /* GraphQL */ `
  subscription OnDeleteUserMortgageSolicitor(
    $filter: ModelSubscriptionUserMortgageSolicitorFilterInput
    $owner: String
  ) {
    onDeleteUserMortgageSolicitor(filter: $filter, owner: $owner) {
      id
      firmName
      firmAddress
      contactName
      contactEmail
      contactPhone
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserMortgagePropertyOnMortgage = /* GraphQL */ `
  subscription OnCreateUserMortgagePropertyOnMortgage(
    $filter: ModelSubscriptionUserMortgagePropertyOnMortgageFilterInput
    $owner: String
  ) {
    onCreateUserMortgagePropertyOnMortgage(filter: $filter, owner: $owner) {
      id
      addressKnown
      address
      propertyType
      buildType
      newlyBuilt
      bedrooms
      bathrooms
      berScore
      propertyValue
      propertyPrice
      siteOwned
      siteValue
      constructionCosts
      constructionStarted
      origPurchasePrice
      yearOfPurchase
      residingHere
      adultsBoarding
      adultsBoardingCount
      isRented
      monthlyRent
      isMortgaged
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      bookingDepositPaid
      contractDepositPaid
      reinstatementValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      surveyRequired
      surveyDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserMortgagePropertyOnMortgage = /* GraphQL */ `
  subscription OnUpdateUserMortgagePropertyOnMortgage(
    $filter: ModelSubscriptionUserMortgagePropertyOnMortgageFilterInput
    $owner: String
  ) {
    onUpdateUserMortgagePropertyOnMortgage(filter: $filter, owner: $owner) {
      id
      addressKnown
      address
      propertyType
      buildType
      newlyBuilt
      bedrooms
      bathrooms
      berScore
      propertyValue
      propertyPrice
      siteOwned
      siteValue
      constructionCosts
      constructionStarted
      origPurchasePrice
      yearOfPurchase
      residingHere
      adultsBoarding
      adultsBoardingCount
      isRented
      monthlyRent
      isMortgaged
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      bookingDepositPaid
      contractDepositPaid
      reinstatementValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      surveyRequired
      surveyDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserMortgagePropertyOnMortgage = /* GraphQL */ `
  subscription OnDeleteUserMortgagePropertyOnMortgage(
    $filter: ModelSubscriptionUserMortgagePropertyOnMortgageFilterInput
    $owner: String
  ) {
    onDeleteUserMortgagePropertyOnMortgage(filter: $filter, owner: $owner) {
      id
      addressKnown
      address
      propertyType
      buildType
      newlyBuilt
      bedrooms
      bathrooms
      berScore
      propertyValue
      propertyPrice
      siteOwned
      siteValue
      constructionCosts
      constructionStarted
      origPurchasePrice
      yearOfPurchase
      residingHere
      adultsBoarding
      adultsBoardingCount
      isRented
      monthlyRent
      isMortgaged
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      bookingDepositPaid
      contractDepositPaid
      reinstatementValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      surveyRequired
      surveyDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicant = /* GraphQL */ `
  subscription OnCreateUserApplicant(
    $filter: ModelSubscriptionUserApplicantFilterInput
    $owner: String
  ) {
    onCreateUserApplicant(filter: $filter, owner: $owner) {
      id
      subjectId
      identityID
      email
      mobile
      firstName
      surname
      isMale
      provider
      lastHere
      userApplicantPersonalId
      userApplicantIncomeId
      userApplicantFinancialId
      userApplicantEmploymentId
      userApplicantTaxId
      userApplicantLastHereId
      data
      lastHereTime {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      accounts {
        items {
          id
          institution
          applicantID
          salaried
          joint
          accommodationDD
          accountName
          accountAddress
          currency
          accountNumber
          lastDocumentDate
          hasOverdraft
          overdraftLimit
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessAccounts {
        items {
          id
          institution
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      personal {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      income {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      financial {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employment {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employmentIncome {
        items {
          id
          applicantID
          employmentType
          employmentBasis
          publicSector
          positionHeld
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerContact
          currentEmployerStartDate
          firstJob
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerName
          previousEmployerAddress
          previousEmployerStartDate
          previousEmployerEndDate
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          limitedCompany
          isDirector
          percentageOfShares
          selfIncomeSource
          grossBasic
          incomes
          nonEuroCurrency
          payFrequency
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tax {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      assets {
        items {
          id
          assetType
          institution
          description
          balance
          increasesFrequency
          increases
          currency
          accountNumber
          accountName
          accountAddress
          lastDocumentDate
          applicantID
          joint
          observeMID
          nameAndAddress
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      properties {
        items {
          id
          propertyType
          address
          estimatedValue
          isRented
          rentPerAnnum
          isMortgaged
          originalAmount
          outstanding
          monthlyRepayments
          remainingTerm
          endDate
          currency
          lastDocumentDate
          lender
          inArrears
          arrears
          applicantID
          bedrooms
          bathrooms
          isRestructured
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      debts {
        items {
          id
          debtType
          lender
          outstanding
          originalAmount
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          accountNumber
          lastDocumentDate
          currency
          clearedBeforeMortgage
          applicantID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessDebts {
        items {
          id
          debtType
          lender
          outstanding
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          clearedBeforeMortgage
          applicantID
          accountNumber
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activity {
        items {
          id
          actions
          userAgent
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      emails {
        items {
          id
          from
          to
          cc
          bcc
          subject
          hasAttachments
          v2Attachments
          html
          attachments
          sortDate
          datetimeReceived
          datetimeSent
          messageId
          originalId
          isReceived
          isReceivedInt
          readOn
          readBy
          replyRequestId
          appsyncVersion
          actionedOn
          actionedBy
          AdminEmailAttachments {
            items {
              id
              contentType
              fileSize
              content
              fileName
              adminemailsID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userapplicantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      alternateEmailAddresses {
        items {
          id
          email
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      health {
        id
        weight
        height
        smoker
        everSmoked
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicant = /* GraphQL */ `
  subscription OnUpdateUserApplicant(
    $filter: ModelSubscriptionUserApplicantFilterInput
    $owner: String
  ) {
    onUpdateUserApplicant(filter: $filter, owner: $owner) {
      id
      subjectId
      identityID
      email
      mobile
      firstName
      surname
      isMale
      provider
      lastHere
      userApplicantPersonalId
      userApplicantIncomeId
      userApplicantFinancialId
      userApplicantEmploymentId
      userApplicantTaxId
      userApplicantLastHereId
      data
      lastHereTime {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      accounts {
        items {
          id
          institution
          applicantID
          salaried
          joint
          accommodationDD
          accountName
          accountAddress
          currency
          accountNumber
          lastDocumentDate
          hasOverdraft
          overdraftLimit
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessAccounts {
        items {
          id
          institution
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      personal {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      income {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      financial {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employment {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employmentIncome {
        items {
          id
          applicantID
          employmentType
          employmentBasis
          publicSector
          positionHeld
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerContact
          currentEmployerStartDate
          firstJob
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerName
          previousEmployerAddress
          previousEmployerStartDate
          previousEmployerEndDate
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          limitedCompany
          isDirector
          percentageOfShares
          selfIncomeSource
          grossBasic
          incomes
          nonEuroCurrency
          payFrequency
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tax {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      assets {
        items {
          id
          assetType
          institution
          description
          balance
          increasesFrequency
          increases
          currency
          accountNumber
          accountName
          accountAddress
          lastDocumentDate
          applicantID
          joint
          observeMID
          nameAndAddress
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      properties {
        items {
          id
          propertyType
          address
          estimatedValue
          isRented
          rentPerAnnum
          isMortgaged
          originalAmount
          outstanding
          monthlyRepayments
          remainingTerm
          endDate
          currency
          lastDocumentDate
          lender
          inArrears
          arrears
          applicantID
          bedrooms
          bathrooms
          isRestructured
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      debts {
        items {
          id
          debtType
          lender
          outstanding
          originalAmount
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          accountNumber
          lastDocumentDate
          currency
          clearedBeforeMortgage
          applicantID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessDebts {
        items {
          id
          debtType
          lender
          outstanding
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          clearedBeforeMortgage
          applicantID
          accountNumber
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activity {
        items {
          id
          actions
          userAgent
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      emails {
        items {
          id
          from
          to
          cc
          bcc
          subject
          hasAttachments
          v2Attachments
          html
          attachments
          sortDate
          datetimeReceived
          datetimeSent
          messageId
          originalId
          isReceived
          isReceivedInt
          readOn
          readBy
          replyRequestId
          appsyncVersion
          actionedOn
          actionedBy
          AdminEmailAttachments {
            items {
              id
              contentType
              fileSize
              content
              fileName
              adminemailsID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userapplicantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      alternateEmailAddresses {
        items {
          id
          email
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      health {
        id
        weight
        height
        smoker
        everSmoked
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicant = /* GraphQL */ `
  subscription OnDeleteUserApplicant(
    $filter: ModelSubscriptionUserApplicantFilterInput
    $owner: String
  ) {
    onDeleteUserApplicant(filter: $filter, owner: $owner) {
      id
      subjectId
      identityID
      email
      mobile
      firstName
      surname
      isMale
      provider
      lastHere
      userApplicantPersonalId
      userApplicantIncomeId
      userApplicantFinancialId
      userApplicantEmploymentId
      userApplicantTaxId
      userApplicantLastHereId
      data
      lastHereTime {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      accounts {
        items {
          id
          institution
          applicantID
          salaried
          joint
          accommodationDD
          accountName
          accountAddress
          currency
          accountNumber
          lastDocumentDate
          hasOverdraft
          overdraftLimit
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessAccounts {
        items {
          id
          institution
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      personal {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      income {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      financial {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employment {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employmentIncome {
        items {
          id
          applicantID
          employmentType
          employmentBasis
          publicSector
          positionHeld
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerContact
          currentEmployerStartDate
          firstJob
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerName
          previousEmployerAddress
          previousEmployerStartDate
          previousEmployerEndDate
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          limitedCompany
          isDirector
          percentageOfShares
          selfIncomeSource
          grossBasic
          incomes
          nonEuroCurrency
          payFrequency
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tax {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      assets {
        items {
          id
          assetType
          institution
          description
          balance
          increasesFrequency
          increases
          currency
          accountNumber
          accountName
          accountAddress
          lastDocumentDate
          applicantID
          joint
          observeMID
          nameAndAddress
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      properties {
        items {
          id
          propertyType
          address
          estimatedValue
          isRented
          rentPerAnnum
          isMortgaged
          originalAmount
          outstanding
          monthlyRepayments
          remainingTerm
          endDate
          currency
          lastDocumentDate
          lender
          inArrears
          arrears
          applicantID
          bedrooms
          bathrooms
          isRestructured
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      debts {
        items {
          id
          debtType
          lender
          outstanding
          originalAmount
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          accountNumber
          lastDocumentDate
          currency
          clearedBeforeMortgage
          applicantID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessDebts {
        items {
          id
          debtType
          lender
          outstanding
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          clearedBeforeMortgage
          applicantID
          accountNumber
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activity {
        items {
          id
          actions
          userAgent
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      emails {
        items {
          id
          from
          to
          cc
          bcc
          subject
          hasAttachments
          v2Attachments
          html
          attachments
          sortDate
          datetimeReceived
          datetimeSent
          messageId
          originalId
          isReceived
          isReceivedInt
          readOn
          readBy
          replyRequestId
          appsyncVersion
          actionedOn
          actionedBy
          AdminEmailAttachments {
            items {
              id
              contentType
              fileSize
              content
              fileName
              adminemailsID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userapplicantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      alternateEmailAddresses {
        items {
          id
          email
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      health {
        id
        weight
        height
        smoker
        everSmoked
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantLastHere = /* GraphQL */ `
  subscription OnCreateUserApplicantLastHere(
    $filter: ModelSubscriptionUserApplicantLastHereFilterInput
    $owner: String
  ) {
    onCreateUserApplicantLastHere(filter: $filter, owner: $owner) {
      id
      time
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantLastHere = /* GraphQL */ `
  subscription OnUpdateUserApplicantLastHere(
    $filter: ModelSubscriptionUserApplicantLastHereFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantLastHere(filter: $filter, owner: $owner) {
      id
      time
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantLastHere = /* GraphQL */ `
  subscription OnDeleteUserApplicantLastHere(
    $filter: ModelSubscriptionUserApplicantLastHereFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantLastHere(filter: $filter, owner: $owner) {
      id
      time
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantCurrentAccount = /* GraphQL */ `
  subscription OnCreateUserApplicantCurrentAccount(
    $filter: ModelSubscriptionUserApplicantCurrentAccountFilterInput
    $owner: String
  ) {
    onCreateUserApplicantCurrentAccount(filter: $filter, owner: $owner) {
      id
      institution
      applicantID
      salaried
      joint
      accommodationDD
      accountName
      accountAddress
      currency
      accountNumber
      lastDocumentDate
      hasOverdraft
      overdraftLimit
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantCurrentAccount = /* GraphQL */ `
  subscription OnUpdateUserApplicantCurrentAccount(
    $filter: ModelSubscriptionUserApplicantCurrentAccountFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantCurrentAccount(filter: $filter, owner: $owner) {
      id
      institution
      applicantID
      salaried
      joint
      accommodationDD
      accountName
      accountAddress
      currency
      accountNumber
      lastDocumentDate
      hasOverdraft
      overdraftLimit
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantCurrentAccount = /* GraphQL */ `
  subscription OnDeleteUserApplicantCurrentAccount(
    $filter: ModelSubscriptionUserApplicantCurrentAccountFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantCurrentAccount(filter: $filter, owner: $owner) {
      id
      institution
      applicantID
      salaried
      joint
      accommodationDD
      accountName
      accountAddress
      currency
      accountNumber
      lastDocumentDate
      hasOverdraft
      overdraftLimit
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantBusinessCurrentAccount = /* GraphQL */ `
  subscription OnCreateUserApplicantBusinessCurrentAccount(
    $filter: ModelSubscriptionUserApplicantBusinessCurrentAccountFilterInput
    $owner: String
  ) {
    onCreateUserApplicantBusinessCurrentAccount(
      filter: $filter
      owner: $owner
    ) {
      id
      institution
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantBusinessCurrentAccount = /* GraphQL */ `
  subscription OnUpdateUserApplicantBusinessCurrentAccount(
    $filter: ModelSubscriptionUserApplicantBusinessCurrentAccountFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantBusinessCurrentAccount(
      filter: $filter
      owner: $owner
    ) {
      id
      institution
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantBusinessCurrentAccount = /* GraphQL */ `
  subscription OnDeleteUserApplicantBusinessCurrentAccount(
    $filter: ModelSubscriptionUserApplicantBusinessCurrentAccountFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantBusinessCurrentAccount(
      filter: $filter
      owner: $owner
    ) {
      id
      institution
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantTax = /* GraphQL */ `
  subscription OnCreateUserApplicantTax(
    $filter: ModelSubscriptionUserApplicantTaxFilterInput
    $owner: String
  ) {
    onCreateUserApplicantTax(filter: $filter, owner: $owner) {
      id
      irishTaxResident
      ppsNumber
      usCitizen
      usTaxId
      otherTaxResident
      otherTaxCountry
      otherTaxId
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantTax = /* GraphQL */ `
  subscription OnUpdateUserApplicantTax(
    $filter: ModelSubscriptionUserApplicantTaxFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantTax(filter: $filter, owner: $owner) {
      id
      irishTaxResident
      ppsNumber
      usCitizen
      usTaxId
      otherTaxResident
      otherTaxCountry
      otherTaxId
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantTax = /* GraphQL */ `
  subscription OnDeleteUserApplicantTax(
    $filter: ModelSubscriptionUserApplicantTaxFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantTax(filter: $filter, owner: $owner) {
      id
      irishTaxResident
      ppsNumber
      usCitizen
      usTaxId
      otherTaxResident
      otherTaxCountry
      otherTaxId
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantPersonal = /* GraphQL */ `
  subscription OnCreateUserApplicantPersonal(
    $filter: ModelSubscriptionUserApplicantPersonalFilterInput
    $owner: String
  ) {
    onCreateUserApplicantPersonal(filter: $filter, owner: $owner) {
      id
      title
      dateOfBirth
      maritalStatus
      maidenName
      mothersMaidenName
      nationality
      countryOfBirth
      placeOfBirth
      countryOfResidence
      irishResidenceStartDate
      otherCountryOfResidenceInPast3Years
      countDependents
      dependentAges
      dependentDOBs
      accommodationSituation
      accommodationSituationOther
      addressCurrent
      addressCurrentYears
      addressCurrentStartDate
      addressPrevious
      visaRequired
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantPersonal = /* GraphQL */ `
  subscription OnUpdateUserApplicantPersonal(
    $filter: ModelSubscriptionUserApplicantPersonalFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantPersonal(filter: $filter, owner: $owner) {
      id
      title
      dateOfBirth
      maritalStatus
      maidenName
      mothersMaidenName
      nationality
      countryOfBirth
      placeOfBirth
      countryOfResidence
      irishResidenceStartDate
      otherCountryOfResidenceInPast3Years
      countDependents
      dependentAges
      dependentDOBs
      accommodationSituation
      accommodationSituationOther
      addressCurrent
      addressCurrentYears
      addressCurrentStartDate
      addressPrevious
      visaRequired
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantPersonal = /* GraphQL */ `
  subscription OnDeleteUserApplicantPersonal(
    $filter: ModelSubscriptionUserApplicantPersonalFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantPersonal(filter: $filter, owner: $owner) {
      id
      title
      dateOfBirth
      maritalStatus
      maidenName
      mothersMaidenName
      nationality
      countryOfBirth
      placeOfBirth
      countryOfResidence
      irishResidenceStartDate
      otherCountryOfResidenceInPast3Years
      countDependents
      dependentAges
      dependentDOBs
      accommodationSituation
      accommodationSituationOther
      addressCurrent
      addressCurrentYears
      addressCurrentStartDate
      addressPrevious
      visaRequired
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantIncome = /* GraphQL */ `
  subscription OnCreateUserApplicantIncome(
    $filter: ModelSubscriptionUserApplicantIncomeFilterInput
    $owner: String
  ) {
    onCreateUserApplicantIncome(filter: $filter, owner: $owner) {
      id
      grossBasic
      hasOvertime
      overtime
      hasBonus
      bonus
      hasCommission
      commission
      hasOther
      other
      otherSource
      otherSourceDetails
      nonEuroCurrency
      maintenance
      welfare
      secondjob
      rentalIncome
      dividends
      payFrequency
      selfIncomeSource
      salariedInstitution
      incomeChecks
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantIncome = /* GraphQL */ `
  subscription OnUpdateUserApplicantIncome(
    $filter: ModelSubscriptionUserApplicantIncomeFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantIncome(filter: $filter, owner: $owner) {
      id
      grossBasic
      hasOvertime
      overtime
      hasBonus
      bonus
      hasCommission
      commission
      hasOther
      other
      otherSource
      otherSourceDetails
      nonEuroCurrency
      maintenance
      welfare
      secondjob
      rentalIncome
      dividends
      payFrequency
      selfIncomeSource
      salariedInstitution
      incomeChecks
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantIncome = /* GraphQL */ `
  subscription OnDeleteUserApplicantIncome(
    $filter: ModelSubscriptionUserApplicantIncomeFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantIncome(filter: $filter, owner: $owner) {
      id
      grossBasic
      hasOvertime
      overtime
      hasBonus
      bonus
      hasCommission
      commission
      hasOther
      other
      otherSource
      otherSourceDetails
      nonEuroCurrency
      maintenance
      welfare
      secondjob
      rentalIncome
      dividends
      payFrequency
      selfIncomeSource
      salariedInstitution
      incomeChecks
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantFinancial = /* GraphQL */ `
  subscription OnCreateUserApplicantFinancial(
    $filter: ModelSubscriptionUserApplicantFinancialFilterInput
    $owner: String
  ) {
    onCreateUserApplicantFinancial(filter: $filter, owner: $owner) {
      id
      hasAccommodationCosts
      monthlyAccommodationCosts
      hasChildcareCosts
      contributeToRelatives
      negativeCredit
      hasAssets
      hasDebts
      hasIncome
      hasCurrentAccountSavings
      hasSavings
      hasLoans
      hasProperties
      paysMaintenance
      maintenanceCosts
      monthlyChildcareCosts
      monthlySavings
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantFinancial = /* GraphQL */ `
  subscription OnUpdateUserApplicantFinancial(
    $filter: ModelSubscriptionUserApplicantFinancialFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantFinancial(filter: $filter, owner: $owner) {
      id
      hasAccommodationCosts
      monthlyAccommodationCosts
      hasChildcareCosts
      contributeToRelatives
      negativeCredit
      hasAssets
      hasDebts
      hasIncome
      hasCurrentAccountSavings
      hasSavings
      hasLoans
      hasProperties
      paysMaintenance
      maintenanceCosts
      monthlyChildcareCosts
      monthlySavings
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantFinancial = /* GraphQL */ `
  subscription OnDeleteUserApplicantFinancial(
    $filter: ModelSubscriptionUserApplicantFinancialFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantFinancial(filter: $filter, owner: $owner) {
      id
      hasAccommodationCosts
      monthlyAccommodationCosts
      hasChildcareCosts
      contributeToRelatives
      negativeCredit
      hasAssets
      hasDebts
      hasIncome
      hasCurrentAccountSavings
      hasSavings
      hasLoans
      hasProperties
      paysMaintenance
      maintenanceCosts
      monthlyChildcareCosts
      monthlySavings
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantEmployment = /* GraphQL */ `
  subscription OnCreateUserApplicantEmployment(
    $filter: ModelSubscriptionUserApplicantEmploymentFilterInput
    $owner: String
  ) {
    onCreateUserApplicantEmployment(filter: $filter, owner: $owner) {
      id
      primaryEmploymentId
      employmentType
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerYearsThere
      currentEmployerContact
      previousEmployerName
      previousEmployerAddress
      previousEmployerYearsThere
      positionHeld
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      employmentBasis
      limitedCompany
      isDirector
      percentageOfShares
      currentEmployerStartDate
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerStartDate
      previousEmployerEndDate
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantEmployment = /* GraphQL */ `
  subscription OnUpdateUserApplicantEmployment(
    $filter: ModelSubscriptionUserApplicantEmploymentFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantEmployment(filter: $filter, owner: $owner) {
      id
      primaryEmploymentId
      employmentType
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerYearsThere
      currentEmployerContact
      previousEmployerName
      previousEmployerAddress
      previousEmployerYearsThere
      positionHeld
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      employmentBasis
      limitedCompany
      isDirector
      percentageOfShares
      currentEmployerStartDate
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerStartDate
      previousEmployerEndDate
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantEmployment = /* GraphQL */ `
  subscription OnDeleteUserApplicantEmployment(
    $filter: ModelSubscriptionUserApplicantEmploymentFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantEmployment(filter: $filter, owner: $owner) {
      id
      primaryEmploymentId
      employmentType
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerYearsThere
      currentEmployerContact
      previousEmployerName
      previousEmployerAddress
      previousEmployerYearsThere
      positionHeld
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      employmentBasis
      limitedCompany
      isDirector
      percentageOfShares
      currentEmployerStartDate
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerStartDate
      previousEmployerEndDate
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantEmploymentIncome = /* GraphQL */ `
  subscription OnCreateUserApplicantEmploymentIncome(
    $filter: ModelSubscriptionUserApplicantEmploymentIncomeFilterInput
    $owner: String
  ) {
    onCreateUserApplicantEmploymentIncome(filter: $filter, owner: $owner) {
      id
      applicantID
      employmentType
      employmentBasis
      publicSector
      positionHeld
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerContact
      currentEmployerStartDate
      firstJob
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerName
      previousEmployerAddress
      previousEmployerStartDate
      previousEmployerEndDate
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      limitedCompany
      isDirector
      percentageOfShares
      selfIncomeSource
      grossBasic
      incomes
      nonEuroCurrency
      payFrequency
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantEmploymentIncome = /* GraphQL */ `
  subscription OnUpdateUserApplicantEmploymentIncome(
    $filter: ModelSubscriptionUserApplicantEmploymentIncomeFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantEmploymentIncome(filter: $filter, owner: $owner) {
      id
      applicantID
      employmentType
      employmentBasis
      publicSector
      positionHeld
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerContact
      currentEmployerStartDate
      firstJob
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerName
      previousEmployerAddress
      previousEmployerStartDate
      previousEmployerEndDate
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      limitedCompany
      isDirector
      percentageOfShares
      selfIncomeSource
      grossBasic
      incomes
      nonEuroCurrency
      payFrequency
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantEmploymentIncome = /* GraphQL */ `
  subscription OnDeleteUserApplicantEmploymentIncome(
    $filter: ModelSubscriptionUserApplicantEmploymentIncomeFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantEmploymentIncome(filter: $filter, owner: $owner) {
      id
      applicantID
      employmentType
      employmentBasis
      publicSector
      positionHeld
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerContact
      currentEmployerStartDate
      firstJob
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerName
      previousEmployerAddress
      previousEmployerStartDate
      previousEmployerEndDate
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      limitedCompany
      isDirector
      percentageOfShares
      selfIncomeSource
      grossBasic
      incomes
      nonEuroCurrency
      payFrequency
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantAsset = /* GraphQL */ `
  subscription OnCreateUserApplicantAsset(
    $filter: ModelSubscriptionUserApplicantAssetFilterInput
    $owner: String
  ) {
    onCreateUserApplicantAsset(filter: $filter, owner: $owner) {
      id
      assetType
      institution
      description
      balance
      increasesFrequency
      increases
      currency
      accountNumber
      accountName
      accountAddress
      lastDocumentDate
      applicantID
      joint
      observeMID
      nameAndAddress
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantAsset = /* GraphQL */ `
  subscription OnUpdateUserApplicantAsset(
    $filter: ModelSubscriptionUserApplicantAssetFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantAsset(filter: $filter, owner: $owner) {
      id
      assetType
      institution
      description
      balance
      increasesFrequency
      increases
      currency
      accountNumber
      accountName
      accountAddress
      lastDocumentDate
      applicantID
      joint
      observeMID
      nameAndAddress
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantAsset = /* GraphQL */ `
  subscription OnDeleteUserApplicantAsset(
    $filter: ModelSubscriptionUserApplicantAssetFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantAsset(filter: $filter, owner: $owner) {
      id
      assetType
      institution
      description
      balance
      increasesFrequency
      increases
      currency
      accountNumber
      accountName
      accountAddress
      lastDocumentDate
      applicantID
      joint
      observeMID
      nameAndAddress
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantDebt = /* GraphQL */ `
  subscription OnCreateUserApplicantDebt(
    $filter: ModelSubscriptionUserApplicantDebtFilterInput
    $owner: String
  ) {
    onCreateUserApplicantDebt(filter: $filter, owner: $owner) {
      id
      debtType
      lender
      outstanding
      originalAmount
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      accountNumber
      lastDocumentDate
      currency
      clearedBeforeMortgage
      applicantID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantDebt = /* GraphQL */ `
  subscription OnUpdateUserApplicantDebt(
    $filter: ModelSubscriptionUserApplicantDebtFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantDebt(filter: $filter, owner: $owner) {
      id
      debtType
      lender
      outstanding
      originalAmount
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      accountNumber
      lastDocumentDate
      currency
      clearedBeforeMortgage
      applicantID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantDebt = /* GraphQL */ `
  subscription OnDeleteUserApplicantDebt(
    $filter: ModelSubscriptionUserApplicantDebtFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantDebt(filter: $filter, owner: $owner) {
      id
      debtType
      lender
      outstanding
      originalAmount
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      accountNumber
      lastDocumentDate
      currency
      clearedBeforeMortgage
      applicantID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantBusinessDebt = /* GraphQL */ `
  subscription OnCreateUserApplicantBusinessDebt(
    $filter: ModelSubscriptionUserApplicantBusinessDebtFilterInput
    $owner: String
  ) {
    onCreateUserApplicantBusinessDebt(filter: $filter, owner: $owner) {
      id
      debtType
      lender
      outstanding
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      clearedBeforeMortgage
      applicantID
      accountNumber
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantBusinessDebt = /* GraphQL */ `
  subscription OnUpdateUserApplicantBusinessDebt(
    $filter: ModelSubscriptionUserApplicantBusinessDebtFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantBusinessDebt(filter: $filter, owner: $owner) {
      id
      debtType
      lender
      outstanding
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      clearedBeforeMortgage
      applicantID
      accountNumber
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantBusinessDebt = /* GraphQL */ `
  subscription OnDeleteUserApplicantBusinessDebt(
    $filter: ModelSubscriptionUserApplicantBusinessDebtFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantBusinessDebt(filter: $filter, owner: $owner) {
      id
      debtType
      lender
      outstanding
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      clearedBeforeMortgage
      applicantID
      accountNumber
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantProperty = /* GraphQL */ `
  subscription OnCreateUserApplicantProperty(
    $filter: ModelSubscriptionUserApplicantPropertyFilterInput
    $owner: String
  ) {
    onCreateUserApplicantProperty(filter: $filter, owner: $owner) {
      id
      propertyType
      address
      estimatedValue
      isRented
      rentPerAnnum
      isMortgaged
      originalAmount
      outstanding
      monthlyRepayments
      remainingTerm
      endDate
      currency
      lastDocumentDate
      lender
      inArrears
      arrears
      applicantID
      bedrooms
      bathrooms
      isRestructured
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantProperty = /* GraphQL */ `
  subscription OnUpdateUserApplicantProperty(
    $filter: ModelSubscriptionUserApplicantPropertyFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantProperty(filter: $filter, owner: $owner) {
      id
      propertyType
      address
      estimatedValue
      isRented
      rentPerAnnum
      isMortgaged
      originalAmount
      outstanding
      monthlyRepayments
      remainingTerm
      endDate
      currency
      lastDocumentDate
      lender
      inArrears
      arrears
      applicantID
      bedrooms
      bathrooms
      isRestructured
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantProperty = /* GraphQL */ `
  subscription OnDeleteUserApplicantProperty(
    $filter: ModelSubscriptionUserApplicantPropertyFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantProperty(filter: $filter, owner: $owner) {
      id
      propertyType
      address
      estimatedValue
      isRented
      rentPerAnnum
      isMortgaged
      originalAmount
      outstanding
      monthlyRepayments
      remainingTerm
      endDate
      currency
      lastDocumentDate
      lender
      inArrears
      arrears
      applicantID
      bedrooms
      bathrooms
      isRestructured
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  subscription OnCreateUserApplicantAlternateEmailAddresses(
    $filter: ModelSubscriptionUserApplicantAlternateEmailAddressesFilterInput
    $owner: String
  ) {
    onCreateUserApplicantAlternateEmailAddresses(
      filter: $filter
      owner: $owner
    ) {
      id
      email
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  subscription OnUpdateUserApplicantAlternateEmailAddresses(
    $filter: ModelSubscriptionUserApplicantAlternateEmailAddressesFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantAlternateEmailAddresses(
      filter: $filter
      owner: $owner
    ) {
      id
      email
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  subscription OnDeleteUserApplicantAlternateEmailAddresses(
    $filter: ModelSubscriptionUserApplicantAlternateEmailAddressesFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantAlternateEmailAddresses(
      filter: $filter
      owner: $owner
    ) {
      id
      email
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantActivity = /* GraphQL */ `
  subscription OnCreateUserApplicantActivity(
    $filter: ModelSubscriptionUserApplicantActivityFilterInput
    $owner: String
  ) {
    onCreateUserApplicantActivity(filter: $filter, owner: $owner) {
      id
      actions
      userAgent
      actionedOn
      actionedBy
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantActivity = /* GraphQL */ `
  subscription OnUpdateUserApplicantActivity(
    $filter: ModelSubscriptionUserApplicantActivityFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantActivity(filter: $filter, owner: $owner) {
      id
      actions
      userAgent
      actionedOn
      actionedBy
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantActivity = /* GraphQL */ `
  subscription OnDeleteUserApplicantActivity(
    $filter: ModelSubscriptionUserApplicantActivityFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantActivity(filter: $filter, owner: $owner) {
      id
      actions
      userAgent
      actionedOn
      actionedBy
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserApplicantHealth = /* GraphQL */ `
  subscription OnCreateUserApplicantHealth(
    $filter: ModelSubscriptionUserApplicantHealthFilterInput
    $owner: String
  ) {
    onCreateUserApplicantHealth(filter: $filter, owner: $owner) {
      id
      weight
      height
      smoker
      everSmoked
      stoppedSmokingDate
      alcoholUnits
      dangerousActivities
      familyDeath
      diagnosedConditions
      diagnosedConditionsDetails
      prescribedMedication
      prescribedMedicationDetails
      hasGp
      gpName
      gpAddress
      gpLastVisit
      gpLastVisitYearsAgo
      yearsWithGp
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserApplicantHealth = /* GraphQL */ `
  subscription OnUpdateUserApplicantHealth(
    $filter: ModelSubscriptionUserApplicantHealthFilterInput
    $owner: String
  ) {
    onUpdateUserApplicantHealth(filter: $filter, owner: $owner) {
      id
      weight
      height
      smoker
      everSmoked
      stoppedSmokingDate
      alcoholUnits
      dangerousActivities
      familyDeath
      diagnosedConditions
      diagnosedConditionsDetails
      prescribedMedication
      prescribedMedicationDetails
      hasGp
      gpName
      gpAddress
      gpLastVisit
      gpLastVisitYearsAgo
      yearsWithGp
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserApplicantHealth = /* GraphQL */ `
  subscription OnDeleteUserApplicantHealth(
    $filter: ModelSubscriptionUserApplicantHealthFilterInput
    $owner: String
  ) {
    onDeleteUserApplicantHealth(filter: $filter, owner: $owner) {
      id
      weight
      height
      smoker
      everSmoked
      stoppedSmokingDate
      alcoholUnits
      dangerousActivities
      familyDeath
      diagnosedConditions
      diagnosedConditionsDetails
      prescribedMedication
      prescribedMedicationDetails
      hasGp
      gpName
      gpAddress
      gpLastVisit
      gpLastVisitYearsAgo
      yearsWithGp
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
