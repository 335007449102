import React, {useContext} from 'react';
import {
    correctValueForSys,
    getItemFromPath
} from "../../../data-source/providers/MortgageProvider";
import useCurrentUserActivity from "../../../data-source/hooks/useCurrentUserActivity";
import useMortgage from "../../../data-source/hooks/useMortgage";
import SnazzyForm from "./SnazzyForm";
import {OverlayContext} from "../../../../../app/components/screen/overlay/CSSOverlayProvider";
import FormList from "./FormList";

function QuestionsForm({questions, postSaveCallback, submitEnabled}) {
    const overlay = useContext(OverlayContext)
    const activity = useCurrentUserActivity()
    const {mortgage} = useMortgage()
    const watchers = questions.filter(question => !!question?.watch).map(question => {
        let {name, watch} = question
        return {name, watch}
    })
    const getInitialValues = () => {
        const init = {}
        try {
            questions.forEach(item => {
                let val = getItemFromPath(mortgage, item.target)
                init[item.name] = correctValueForSys(mortgage, item, val)
                if (item.answer?.defaultValue && [null, undefined].includes(init[item.name])) {
                    init[item.name] = item.answer.defaultValue
                }
            })
        } catch (e) {
            console.log(e.message)
        }
        return init
    }
    const onValuesChange = (form, map) => {
        Object.keys(map).forEach(key => {

            let updValue = map[key]
            let watched = watchers.find(w => w.name === key)

            // if the value being saved is an item from an array, change the value to the entire updated array ie x => [a,b,x]
            if (Array.isArray(updValue)) {
                let index = updValue.findIndex((item) => !!item)
                let newValue = updValue[index]
                let orig = form.getFieldValue(key)
                updValue = orig.map((item, currentIndex) =>
                    currentIndex === index ? newValue : item
                );
            }
            if (watched) {
                watched.watch(form, updValue)
            }
            form.setFieldValue(key, updValue)
        })
    }
    const handleValid = async (valid) => {
        const updates = Object.keys(valid).map(key => {
            const {target, answer} = questions.find(question => question.name === key)
            return {
                target,
                value: valid[key],
                type: answer.type
            }
        })
        await mortgage.mutate({
            update: updates
        })
        await activity('updated application')
        // if post save callback function exits call it
        if (postSaveCallback) {
            postSaveCallback(valid)
        } else{
            setTimeout(overlay.removeTop,500)
           // return navigate(`./../`)
        }

    }

    return (
        <SnazzyForm
            popSubmit={true}
            onValuesChange={onValuesChange}
            handleValid={handleValid}
            initialValues={getInitialValues()}
            submitEnabled={submitEnabled}
            submitAsFooter={true}
        >
            <FormList dataSource={questions}/>
        </SnazzyForm>
    )
}

export default QuestionsForm;