import {
    employmentBasis,
    employmentType,
} from "../../../../apply/applicant/employment";
import questionProps from "../../../../apply/questionProps";

import {
    grossBasic,
    nonEuroCurrency
} from "../../../../apply/applicant/income";
import {
    currentEmployerAddress,
    currentEmployerName,
    currentEmployerStartDate, firstJob,
    positionHeld,
    previousEmployerAddress,
    previousEmployerEndDate,
    previousEmployerName,
    previousEmployerStartDate,
    previousPositionHeld
} from "../../../../apply/applicant/employmentIncome";
import {capitaliseFirst} from "../../../../../../assets/functions/functions";

export const publicSector = (props) => {
    return {
        name: 'publicSector',
        label: 'Public Service Employment',
        text: `${capitaliseFirst(props.you.areYou)} a public service or private sector employee?`,
        answer: {
            type: 'choice',
            choices: [
                {text: 'Public', value: true},
                {text: 'Private', value: false}
            ]
        },
        target: `applicant${props.applicant}.employmentIncome[${props.index}].publicSector`,
        hides: (form)=>form.getFieldValue('employmentType') !== 'EMPLOYED',
    }
}
function questionsSetupEmployment({mortgage, applicant, index, applyStageForm=false}) {
    const betterProps = questionProps({mortgage, applicant, index})

    let eType = employmentType(betterProps)
    if (mortgage[`applicant${applicant}`].employmentIncome.length > 1) {
        eType.answer.choices = eType.answer.choices.filter(item => ['SELFEMPLOYED', 'EMPLOYED'].includes(item.value))
    }
    let firstId = mortgage[`applicant${applicant}`].employmentIncome.length > 1 ? mortgage[`applicant${applicant}`].employmentIncome[0].id : null
    const questions = [
        eType,
        employmentBasis(betterProps),
        publicSector(betterProps),
        nonEuroCurrency(betterProps),
    ]
    if( !!applyStageForm ){
        questions.push(
            currentEmployerName(betterProps),
            positionHeld(betterProps),
            currentEmployerAddress(betterProps),
            currentEmployerStartDate(betterProps)
        )
        if (!index || firstId === index) {
            questions.push(
                firstJob(betterProps),
                previousEmployerName(betterProps),
                previousPositionHeld(betterProps),
                previousEmployerAddress(betterProps),
                previousEmployerStartDate(betterProps),
                previousEmployerEndDate(betterProps),

            )
        }

    }
    questions.push(grossBasic(betterProps))

    return {
        questions
    }
}

export default questionsSetupEmployment;