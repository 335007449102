import {capitaliseFirst} from "../../../../assets/functions/functions";
import {monthYearFormat, isInFuture} from "../../../../../../app/data/validators";
import questionProps from "../questionProps";
import {DebtTypes} from "../../../../../../../models";

export function accountNumber(props) {
    return {
        text: "What is the account number related to this debt",
        label: "Account number",
        name: "accountNumber",
        answer: {
            type: "text",
            optional: true,
        },
        hides: (form) => {
            return form.getFieldValue('debtType') !== "LOAN"
        },
        model: "debts",
        rules: [
            {required: true, message: `An account number is required`},
        ],
        target: `applicant${props.applicant}.debts[${props.index}].accountNumber`
    }
}
export function debtType(props) {
    return {
        text: "What type of credit facility is this?",
        label: "Facility Type",
        name: "debtType",
        answer: {
            type: "select",
            choices: [
                {value: 'CREDITCARD', text: "Credit or Store Card"},
                // {value: 'STORECARD', text: "Store Card"},
                {value: 'LOAN', text: "Loan"},
                //  {value: 'OVERDRAFT', text: "Overdraft"},
            ]
        },
        model: "debts",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.debts[${props.index}].debtType`
    }
}
export function lender(props) {
    return {
        text: (form) => {
            switch (form.getFieldValue('debtType')) {
                case 'LOAN' :
                    return "Who issued " + props.you.you + " with this loan?"
                case 'CREDITCARD' :
                    return "Who issued " + props.you.you + " with this credit card?"
                case 'STORECARD' :
                    return "Who issued " + props.you.you + " with this store card?"
                case 'OVERDRAFT' :
                    return "Who issued " + props.you.you + " with this overdraft?"
                default:
            }
        },
        label: "Lender",
        name: "lender",
        answer: {
            type: "lender",
        },
        model: "debts",
        rules: [
            {required: true, message: `A lender name is required`},
        ],
        target: `applicant${props.applicant}.debts[${props.index}].lender`
    }
}
export function creditLimit(props) {
    return {
        label: "Credit limit",
        text: "What is the credit limit on this card",
        name: "creditLimit",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 10000000, message: "You probably don't need a mortgage"}
            }
        },
        hides:(form)=>{
            return ![DebtTypes.CREDITCARD, DebtTypes.CREDITCARD].includes(form.getFieldValue('debtType'))
        },
        model: "debts",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 100000, message: "The maximum value here is €100,000"}
        ],
        target: `applicant${props.applicant}.debts[${props.index}].creditLimit`
    }
}
export function outstanding(props) {
    return {
        label: "Outstanding Amount",
        text: (form) => {
            switch (form.getFieldValue('debtType')) {
                case 'LOAN' :
                    return "What is the outstanding amount on this loan"
                case 'CREDITCARD' :
                    return "What is the outstanding amount on this credit card?"
                case 'STORECARD' :
                    return "What is the outstanding amount on this store card?"
                case 'OVERDRAFT' :
                    return "What is the outstanding amount on this overdraft?"
                default:
            }
        },
        name: "outstanding",
        answer: {
            type: "euro",
            optional: true
        },
        hides: (form) => {
            return form.getFieldValue('debtType') === "OVERDRAFT"
        },
        model: "debts",
        rules: [
            {type: 'number', min: 0, message: "The minimum value here is €0"},
            {type: 'number', max: 100000, message: "The maximum value here is €100,000"}
        ],
        target: `applicant${props.applicant}.debts[${props.index}].outstanding`
    }
}
export function monthlyPayment(props) {
    return {
        label: "Monthly Repayments",
        text: (form) => {
            switch (form.getFieldValue('debtType')) {
                case 'LOAN' :
                    return "What is the monthly repayment on this loan"
                case 'CREDITCARD' :
                    return "What is the typical monthly payment on this card?"
                case 'STORECARD' :
                    return "What is the monthly repayment on this store card?"
                case 'OVERDRAFT' :
                    return "What is the monthly repayment on this overdraft?"
            default:
            }
        },
        name: "monthlyPayment",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 10000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form) => {
            return form.getFieldValue('debtType') !== DebtTypes.LOAN
        },
        model: "debts",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 0, message: "The minimum value here is €0"},
            {type: 'number', max: 100000, message: "The maximum value here is €100,000"}
        ],
        target: `applicant${props.applicant}.debts[${props.index}].monthlyPayment`
    }
}
export function inArrears(props) {
    return {
        text: (form) => {
            switch (form.getFieldValue('debtType')) {
                case 'LOAN' :
                    return capitaliseFirst(props.you.areYou) + " in arrears on this loan?"
                case 'CREDITCARD' :
                    return capitaliseFirst(props.you.areYou) + " in arrears on this credit card?"
                case 'STORECARD' :
                    return capitaliseFirst(props.you.areYou) + " in arrears on this store card?"
                case 'OVERDRAFT' :
                    return capitaliseFirst(props.you.areYou) + " in arrears on this overdraft?"
                default:
            }
        },
        label: "In Arrears",
        name: "inArrears",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            return form.getFieldValue('debtType') === "OVERDRAFT"
        },
        model: "debts",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.debts[${props.index}].inArrears`
    }
}
export function arrears(props) {
    return {
        label: "Arrears",
        text: "By how much " + props.you.areYou + " in arrears?",
        name: "arrears",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            if (form.getFieldValue('debtType') === "OVERDRAFT") return true
            return (form.getFieldValue('inArrears') !== true)
        },
        model: "debts",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 100000, message: "The maximum value here is €100,000"}
        ],
        target: `applicant${props.applicant}.debts[${props.index}].arrears`
    }
}
export function endDate(props) {
    return {
        text: (form) => {
            switch (form.getFieldValue('debtType')) {
                case 'LOAN' :
                    return "On what date will this loan be repaid in full?"
                case 'CREDITCARD' :
                    return "By what date will this credit card be cleared?"
                case 'STORECARD' :
                    return "By what date will this store card be cleared?"
                case 'OVERDRAFT' :
                    return "By what date will this overdraft be cleared?"
                default:
            }
        },
        label: "End Date",
        name: "endDate",
        answer: {
            type: "mmyyyy",
        },
        hides: (form) => {
            return ["OVERDRAFT", "CREDITCARD"].includes(form.getFieldValue('debtType'));
        },
        model: "debts",
        rules: [
            {required: true, message: `A date is required`},
            {validator: monthYearFormat},
            {validator: isInFuture},
        ],
        target: `applicant${props.applicant}.debts[${props.index}].endDate`
    }
}
export function clearedBeforeMortgage(props) {
    return {
        text: capitaliseFirst(props.you.areYou) + " intending to repay the remaining loan balance before you start using your mortgage?",
        label: "Cleared before mortgage",
        name: "clearedBeforeMortgage",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            let val = form.getFieldValue('debtType')
            if (val === "OVERDRAFT") {
                return true
            }
            return form.getFieldValue('outstanding') === 0;
        },
        model: "debts",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.debts[${props.index}].clearedBeforeMortgage`
    }
}
export function haveAny(props){
    let extra = props.applicant === 2 ? " that have not already been mentioned in the application" : ""

    return {
        text: capitaliseFirst(props.you.doYou) + ` have any short term loans (not mortgages), credit cards or store cards${extra}?`,
        label: "Any Credit Facilities",
        name: "hasDebts",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ]
        },
        model: "financial",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.financial.hasDebts`
    }
}

export function debtTitle(debt) {
    let title = 'New Credit Facility'
    if (debt?.lender){
        title = capitaliseFirst(debt.lender)
        if (debt?.debtType){
            title += ' ' + capitaliseFirst(debt.debtType.toLowerCase())
        }
    }
    return title

}

function debts(props) {
    const betterProps = questionProps(props)
    if (props?.index === 'have-any'){
        return [haveAny(betterProps)]
    }

    const questions= [
        debtType(betterProps),
        lender(betterProps),
        creditLimit(betterProps),
        outstanding(betterProps),
        monthlyPayment(betterProps),
        //inArrears(betterProps),
        //arrears(betterProps),
        endDate(betterProps),
        clearedBeforeMortgage(betterProps),
]
    return questions
}

export default debts;