import React, {lazy, Suspense, useEffect, useState} from 'react';
import AuthCheck from "../../app/auth/AuthCheck";
import {useParams} from "react-router-dom";
import MortgageProvider from "./data-source/providers/MortgageProvider";
import MortgageProgressProvider from "./data-source/providers/MortgageProgressProvider";
import MortgageInvitationProvider from "./data-source/providers/MortgageInvitationProvider";
import S3Provider from "./data-source/providers/S3Provider";
import useMortgage from "./data-source/hooks/useMortgage";
import {Card, Skeleton, Spin} from "antd";
import OverlayProvider from "../../app/components/screen/overlay/CSSOverlayProvider";
import DarkModeProvider from "../../app/components/screen/DarkModeProvider";
import Mortgage from "./components/Mortgage";
function App() {
    const params = useParams()

    return (
        <AuthCheck product="mortgage" productId={params.mortgageId}>
            <MortgageProvider mortgageId={params.mortgageId}>
                <MortgageProgressProvider>
                    <MortgageInvitationProvider>
                        <S3Provider>
                            <DarkModeProvider>
                                <OverlayProvider>
                                    <MortgageLoader/>
                                </OverlayProvider>
                            </DarkModeProvider>
                        </S3Provider>
                    </MortgageInvitationProvider>
                </MortgageProgressProvider>
            </MortgageProvider>
        </AuthCheck>

    );
}
const StageSetup = lazy(() => import('./components/stages/Setup'));
const StageApply = lazy(() => import('./components/stages/Apply'));
const StagePreapproved = lazy(() => import('./components/stages/PreApproved'));
const StageApproved = lazy(() => import('./components/stages/Approved'));
function FallBack() {
    const Skelly = () => {
        return <Card className="h-24" styles={{body:{
                padding: 12,
                display: 'flex',
                flexDirection: 'column',
                gap: 4
            }}}>
            <Skeleton.Button active/>
            <Skeleton.Input active block/>
        </Card>
    }
    const landing = {
        welcome: {
            title: "",
            description: <Spin size="large"/>
        },
        cards: [
            <Skelly key={1}/>,
            <Skelly key={3}/>,
        ]
    }

    const buttons = (
        <div className="flex gap-12 justify-center">
            <Skeleton.Avatar size="large"/>
            <Skeleton.Avatar size="large"/>
            <Skeleton.Avatar size="large"/>
        </div>
    )
    return (
        <Mortgage landing={landing} buttons={buttons}/>
    )

}
function Load({children}){
    return (
        <Suspense fallback={<FallBack />}>
            {children}
        </Suspense>
    );
}
function MortgageLoader() {
    const [mortgageLoaded, setMortgageLoaded] = useState(false)
    const {mortgage} = useMortgage()
    useEffect(() => {
        if (mortgage && !mortgageLoaded) {
            setMortgageLoaded(true)
        }
    }, [mortgage])
    if (mortgageLoaded) {
        switch (mortgage.applicationStage){
            case "SETUP":return (<Load><StageSetup /></Load>)
            case "SIGN":
            case "APPLY":return (<Load><StageApply /></Load>)
            case "PREAPPROVAL": return (<Load><StagePreapproved /></Load>)
            case "APPROVAL": return (<Load><StageApproved /> </Load>);
        }
    }

    return <FallBack/>
}

export default App;

function ScanPermissions({mortgage}){
    let objects = {}
    const scanner = (object, keyName)=>{
        if (Array.isArray(object)){
            object.forEach((it,i)=>scanner(it, keyName + `[${i}]`))
        }
        else{
            if (typeof object === 'object'){
                if (object.hasOwnProperty('editAccessGroup')) {
                    objects[keyName] = {
                        id             : object.id,
                        editAccessGroup: object.editAccessGroup,
                        readAccessGroup: object.readAccessGroup
                    }
                }
                for (let key in object) {
                    if (!!object[key]){
                        scanner(object[key], `${keyName}.${key}`)
                    }
                }
            }


        }
    }
    if (mortgage) scanner(mortgage, 'mortgage')

    // Object.keys(objects).forEach(key=>{
    //     if (!objects[key].editAccessGroup.includes('880dafd7-3d75-4a60-98f4-992697ccaa26-app1')){
    //         console.log('no app1')
    //         console.log({[key]:objects[key]})
    //     }
    //     if (!objects[key].editAccessGroup.includes('880dafd7-3d75-4a60-98f4-992697ccaa26-app2')){
    //         console.log('no app2')
    //         console.log({[key]:objects[key]})
    //     }
    // })
}