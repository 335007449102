import React, {useContext} from 'react';
import {
    CaretDownOutlined,
    IdcardOutlined,
    PlusOutlined,
    QuestionOutlined,
    StopOutlined,
    UploadOutlined
} from "@ant-design/icons";
import _useColorRange from "../../../../assets/elements/_useColorRange";
import {Avatar, Badge, Card, Dropdown, Segmented} from "antd";
import Overlay from "../../../../assets/elements/Overlay";
import MortgageUpload from "../../../../assets/elements/uploader/MortgageUpload";
import {capitaliseWords} from "../../../../assets/functions/functions";
import UploadSectionQueries from "./UploadSectionQueries";
import AlertCard from "../../../../assets/elements/cards/AlertCard";
import dayjs from "dayjs";
import {UploadStatuses} from "../../../../../../../models";
function UploadSectionOverlay({section, recordComplete}) {
    const colorRange = _useColorRange('#d7d7d7')

    const [index, setIndex] = React.useState(section.queries?.length ? 'Queries':'Uploads')

    function MyTabs() {
        if (!section.queries?.length) return null

        const handleChange = (e) => {
            setIndex(e)
        }

        let unAnswered = section.queries.filter(q => q.userAnswered !== 1)
        const options = [
            {
                key: 'Queries',
                value: 'Queries',
                label: (
                    <div style={{padding: 4, margin: 4}}>
                        <Badge count={unAnswered.length}>
                            <Avatar icon={<QuestionOutlined />}/>
                        </Badge>
                        <div className="text-xs">Queries</div>
                    </div>
                )
            },
            {
                key: 'Uploads',
                value: 'Uploads',
                label: (
                    <div style={{padding: 4, margin: 4}}>

                            <Avatar icon={<UploadOutlined/>}/>



                        <div className="text-xs">Uploads</div>
                    </div>
                )
            }
        ]
        return (
            <Segmented className="border border-gray-300 dark:border-gray-700 p-2" options={options}
                       onChange={handleChange} value={index} size="large"/>
        )
    }
    return (

            <Overlay
                icon={<UploadOutlined/>}
                title={section.title}
                description={section.description}
                colorRange={colorRange}>

                <div className="flex flex-col gap-4">
                    {
                        !!section.outOfDate && Array.isArray(section.uploads) && section.uploads.filter(it=>it.status === UploadStatuses.PENDING).length === 0 && (
                            <AlertCard
                                title={"Uploads out of date"}
                                description={`Upload more recent documents to bring your account up to date. The last date we have is ${section.outOfDate.format('D MMM YYYY')}`}
                                icon={<StopOutlined/>}
                            />
                        )
                    }
                    {
                        section.record?.alertBody && section.record.alertBody.length && !section.record.alertDismissed && (
                            <AlertCard
                                title={section.record.alertTitle}
                                description={section.record.alertBody.map(t=><p key={t} style={{whiteSpace: 'pre-wrap'}}>{t}</p>)}
                                icon={<StopOutlined/>}
                            />
                        )
                    }
                    <MyTabs/>
                    {
                        index === 'Queries' && (<UploadSectionQueries section={section} recordComplete={recordComplete}    />)
                    }
                    {
                        index === 'Uploads' && (  <MortgageUpload section={section}    />)
                    }

                </div>


            </Overlay>



    );
}

export default UploadSectionOverlay;