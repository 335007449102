import React, {useContext, useEffect, useRef, useState} from 'react';
import {App, Badge, Button, Card, Col, Descriptions, Input, Row} from "antd";
import StandardUpload from "../../../../../app/components/standardised-upload/StandardUpload";
import {S3} from "../../../data-source/providers/S3Provider";
import FormSubmit from "../forms/FormSubmit";
import PropTypes from "prop-types";
import {magenta} from "@ant-design/colors";
import MortgageSectionNote from "./MortgageSectionNote";
import {SectionTypes, UploadStatuses} from "../../../../../../models";
import {Auth} from "aws-amplify";
import useCurrentUserActivity from "../../../data-source/hooks/useCurrentUserActivity";
import useMortgage from "../../../data-source/hooks/useMortgage";
import {OverlayContext} from "../../../../../app/components/screen/overlay/CSSOverlayProvider";

import './uploads.css'
function MortgageUpload({section}) {
    const [isSubmitted, setIsSubmitted] = useState(section.record.submittedBy && section.record.submittedBy !== 'null')
    const {mortgage} = useMortgage()
    const {removeTop} = useContext(OverlayContext)
    const activity = useCurrentUserActivity()
    const [state,setState] = useState({submitting:false, deleting:[]})
    const s3 = useContext(S3)

    const fileFilter = (upload) => {
        if (upload.status === UploadStatuses.EXPIRED){
            return false
        }
        if (upload.section === section.name && upload.stage && upload.stage === section.stage) {
            if (upload?.index) {
                return upload.index === section.index
            }
            return true
        }
        return false
    }
    const prepareUploadForStandardUpload = (upload) => {
        return {
            uid: upload.id,
            id: upload.id,
            name: upload?.fileExtension ? `${upload.fileName}.${upload.fileExtension}` : upload.fileName,
            contentType: upload.contentType,
            status: state.deleting.includes(upload.id) ? 'uploading':upload.status,
            created: upload.createdAt,
            reason: upload.reason,
            extension: upload.fileExtension
        }
    }
    const getRow = (id) => {
        return mortgage.uploads.find(item => item.id === id)
    }
    const handleRename = async (id, name) => {
        const result = await s3.uploads.rename(id, name)
        return result
    }
    const handleDelete = async (id) => {
        setState(s=>({...s,deleting:[...s.deleting,id]}))
        return await s3.uploads.delete(id)
    }
    const getUrl = async (id) => {
        const row = getRow(id)
        const {path, config} = s3.uploads.get(row)
        const url = await s3.getS3Url({path, config})
        return url
    }
    const handleUpload = async ({file, progressCallback, completeCallback, errorCallback}) => {

        let markSectionNotSubmittedCallback = async () => {
            // to re-enable the submit button
            if (!!section.record.submittedOn){
                const update = {
                    target: `progress[${section.record.id}]`,
                    pairs:{
                        submittedOn: null,
                        submittedBy: 'null'
                    }
                }
                let result = await mortgage.mutate({update})
                setIsSubmitted(false)
            }
        }

        let expandedProps = {
            file,
            progressCallback,
            completeCallback:markSectionNotSubmittedCallback,
            errorCallback,
            stage: section.stage,
            section: section.name,
            index: section.index,
        }


        await s3.uploads.add(expandedProps)



    }
    const markReadyForReview = async () => {
        setState(s=>({...s,submitting:true}))
        const user = await Auth.currentAuthenticatedUser()
        const update = {
            target: `progress[${section.record.id}]`,
            pairs:{
                submittedOn: (new Date().toISOString()),
                submittedBy: user.attributes.sub,
                alertDismissed: true,
                sectionChecked: null,
                sectionCheckedBy: 'null',
            }
        }
        await mortgage.mutate({update})
        await activity('added document to uploads')
        setIsSubmitted(true)
        setTimeout(removeTop,300)
    }



    function generateInstructions({acceptableDocuments, specialRequirements, examples, templates, downloads}) {
        function getStringAfterLastSlash(url) {
            const parts = url.split('/');
            return parts[parts.length - 1];
        }
        function GeneratedTemplateChildren({items}) {
            return items.map(item => {
                let myProps = !!item?.onClick ? {onClick: item.onClick} : {}
                let inner = (
                    <Button {...myProps} style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        alignItems: 'start',
                        borderRadius: '0.3rem',

                        width: 100,
                        height: 141,
                        padding: 9,
                        marginTop: 9
                    }}>
                        {item.label.split(' ').map(text => <React.Fragment key={text}>{text}</React.Fragment>)}
                        <span style={{marginTop: 'auto', marginBottom: 9, marginLeft: 'auto'}}>
                                        <span
                                            className="border border-pink-700 text-pink-600 px-0.5 block border-dashed"
                                            style={{
                                                transform: 'rotate(-5deg)',
                                            }}>TEMPLATE</span>
                                        </span>
                    </Button>
                )
                if (item.href) {
                    return (<a key={item.href} target="_blank" download={getStringAfterLastSlash(item.href)}
                               href={item.href}>
                        {inner}
                    </a>)
                }
                else{
                    return inner
                }

            }
        )
        }
        function GeneratedDownloadsChildren({items}) {
            return items.map(item => {
                // const document = mortgage.UserMortgageDocuments.find(...)
                let myProps = !!item?.onClick ? {onClick: item.onClick} : {}
                return (

                    <Button {...myProps} key={item} style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        alignItems: 'start',

                        width: 100,
                        height: 141,
                        padding: 9,
                        marginTop: 9
                    }}>
                        {item.label.split(' ').map(text => <React.Fragment key={text}>{text}</React.Fragment>)}
                        <span style={{marginTop: 'auto', marginBottom: 9, marginLeft: 'auto'}}>
                                        <span style={{
                                            color: magenta.primary,
                                            fontFamily: "monospace",
                                            display: "block",
                                            transform: 'rotate(-9deg)'
                                        }}>DOWNLOAD</span>
                                        </span>
                    </Button>

                )
            })
        }

        const items = []
        if (acceptableDocuments && acceptableDocuments.length) {
            items.push({
                key: 'acceptableDocuments',
                label: 'Acceptable Documents',
                children: (<ul>{acceptableDocuments.map((item,i) => <li key={`specialRequirement${i}`} className="my-1 flex gap-2 items-start">
                    <Badge color="green"/>
                    <span className="text-xs  text-neutral-500" >{item}</span>


                </li>)}</ul>),
            })
        }
        if (specialRequirements && specialRequirements.length) {
            items.push({
                key: 'specialRequirements',
                label: 'Special Requirements',
                children: (<ul>{specialRequirements.map((item, i) => <li key={`specialRequirement${i}`} className="my-1 flex gap-2 items-start">
                    <Badge color="green" className="relative -top-0.5"/>
                    <span className="text-xs  text-neutral-500" >{item}</span>


                </li>)}</ul>),
            })
        }

        if (examples && examples.length) {
            items.push({
                key: 'examples',
                label: 'Examples',
                children: examples,
            })
        }

        if (templates && templates.length) {
            items.push({
                key: 'templates',
                label: 'Download template',
                children: <GeneratedTemplateChildren items={templates}/>
            })
        }

        if (downloads && downloads.length) {
            items.push({
                key: 'downloads',
                label: 'Download',
                children: <GeneratedDownloadsChildren items={downloads}/>
            })
        }

        return <Descriptions
            size="small"
            bordered
            column={2}
            layout="vertical"
            items={items}/>
    }

    const files = mortgage.uploads.filter(fileFilter).map(upload => prepareUploadForStandardUpload(upload))
    files.sort((a, b) => new Date(a.created) - new Date(b.created))

    return (
        <div className="flex flex-col gap-4">



            <Card bordered={false}
                  styles={{body:{padding: 0}}}
            >
                {!!section.instructions && generateInstructions(section.instructions)}

                <StandardUpload

                    fileList={files}
                    handleUpload={handleUpload}
                    rename={handleRename}
                    delete={handleDelete}
                    getUrl={getUrl}
                />
                {
                    !section.record.creating && (
                        <FormSubmit setIsSubmitted={setIsSubmitted} busy={state.submitting} buttonText={isSubmitted ? 'Sent for review':'Send for review'} submitAsFooter={true} onClick={markReadyForReview} disabled={files.length === 0 || !!isSubmitted}/>
                    )
                }

            </Card>
            <MortgageSectionNote record={section.record}/>


        </div>

    )

}

export default MortgageUpload;
MortgageUpload.propTypes = {
    section: PropTypes.object.isRequired,
    instructions: PropTypes.object,
    index: PropTypes.string,

};
